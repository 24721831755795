// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useCallback, useEffect, useState } from "react";
import { settings } from "../../settings";
import { Tooltip } from "primereact/tooltip";

export default function SourceControl({
  sources,
  enabledSources,
  selectedSources,
  setSelectedSources,
  displaySources,
  setDisplaySources,
}) {
  const [checkHealthEnabled, setCheckHealthEnabled] = useState(false);
  const [healthLoading, setHealthLoading] = useState(
    sources
      .map((source) => [source.code, false])
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
  );
  const [sourceHealth, setSourceHealth] = useState(
    sources
      .map((source) => [source.code, "not_checked"])
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
  );

  const onSourceChange = (e) => {
    setSelectedSources(checkboxChange(e, selectedSources));
  };

  const onDisplayChange = (e) => {
    setDisplaySources(checkboxChange(e, displaySources));
  };

  const checkboxChange = (e, sources) => {
    let _selected = [...sources];

    if (e.checked) {
      _selected.push(e.value);
    } else {
      for (let i = 0; i < _selected.length; i++) {
        if (_selected[i].code === e.value.code) {
          _selected.splice(i, 1);
          break;
        }
      }
    }
    return _selected;
  };
  const checkSourceHealth = useCallback(
    (enabled) => {
      if (!enabled) {
        for (let i = 0; i < sources.length; i++) {
          const source = sources[i];
          setSourceHealth((prev) => {
            return {
              ...prev,
              [source.code]: "not_checked",
            };
          });
          return;
        }
      }

      console.log("checking health");

      setHealthLoading((prev) => {
        return sources
          .map((source) => [source.code, true])
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      });

      for (let i = 0; i < sources.length; i++) {
        const source = sources[i];
        console.log(
          "enabl",
          enabledSources,
          source.code,
          enabledSources.includes(source.code),
        );

        if (enabledSources.includes(source.code) === false) {
          setHealthLoading((prev) => {
            return {
              ...prev,
              [source.code]: false,
            };
          });
          continue;
        }

        fetch(settings.die_worker_url + "health?source=" + source.code)
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            setHealthLoading((prev) => {
              return {
                ...prev,
                [source.code]: false,
              };
            });
            console.log(
              "asdf",
              source.code,
              response,
              response["health"] ? "healthy" : "unhealthy",
            );
            setSourceHealth((prev) => {
              return {
                ...prev,
                [source.code]: response["health"],
              };
            });
          })
          .catch((error) => {
            // console.log("asdf", error);
          });
      }
    },
    [sources, enabledSources],
  );

  useEffect(() => {
    checkSourceHealth(checkHealthEnabled);
  }, [checkHealthEnabled, checkSourceHealth]);

  return (
    <div className={"p-1"}>
      <div className={"flex flex-wrap"}>
        <Button
          onClick={() => {
            checkSourceHealth(true);
          }}
          className={"p-2 m-1"}
          loading={Object.values(healthLoading).some((v) => v === true)}
        >
          Check Source Status
        </Button>

        <Button
          onClick={() => {
            setSelectedSources(
              sources.filter((s) => enabledSources.includes(s.code)),
            );
          }}
          className={"p-2 m-1"}
        >
          Select All
        </Button>
        <Button className={"p-2 m-1"} onClick={() => setSelectedSources([])}>
          Clear All
        </Button>
      </div>

      <table className={"compact-table source-table"}>
        <thead>
          <tr className={"surface-300"}>
            <th>Show</th>
            <th>Use</th>
            <th>Status</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {sources.map((source) => {
            let status = "";
            // if (sourceHealth[source.code] === "healthy") {
            //   status = "bg-green-200 border-green-800";
            // } else if (sourceHealth[source.code] === "not_checked") {
            //   // status = "bg-yellow-200 border-yellow-800";
            //   status = "bg-green-100";
            // } else {
            //   status = "bg-red-200 border-red-800";
            // }
            let style = "m-1 border-1 border-solid p-1 " + status;

            style = source.code + " " + style;

            if (!enabledSources.includes(source.code)) {
              style += " bg-gray-200 border-gray-800 text-500";
            }

            let statusText = "---";
            let statusClassName =
              "m-1 border-1 border-solid border-round p-0 w-3rem h-1rem text-center ";
            if (sourceHealth[source.code] === "healthy") {
              statusText = "ok";
              statusClassName += "bg-green-200 border-green-800";
            } else if (sourceHealth[source.code] === "unhealthy") {
              statusText = "X";
              statusClassName += "bg-red-200 border-red-800";
            } else {
              if (!enabledSources.includes(source.code)) {
                statusClassName += "text-500 bg-gray-200";
              }
            }

            return (
              // <div key={source.code} className="flex align-items-center">
              <tr key={source.code}>
                <td className={"p-1"}>
                  <Checkbox
                    inputId={source.code}
                    name="name"
                    value={source}
                    onChange={onDisplayChange}
                    disabled={sourceHealth[source.code] === "unhealthy"}
                    checked={displaySources.some(
                      (item) => item.code === source.code,
                    )}
                  />
                </td>
                <td>
                  <Checkbox
                    inputId={source.code}
                    name="name"
                    value={source}
                    onChange={onSourceChange}
                    disabled={
                      sourceHealth[source.code] === "unhealthy" ||
                      !enabledSources.includes(source.code)
                    }
                    checked={selectedSources.some(
                      (item) => item.code === source.code,
                    )}
                  />
                </td>
                <td>
                  <div className={statusClassName}>{statusText}</div>
                </td>
                <td className={style}>
                  <Tooltip target={`.${source.code}`} />
                  <label
                    htmlFor={source.code}
                    className={source.code}
                    data-pr-tooltip={
                      "Source Health: " +
                      sourceHealth[source.code] +
                      "\n" +
                      source.description
                    }
                  >
                    {source.name}
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
// ============= EOF =============================================
