// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { mToFt } from "../../util";
import { downloadCSV } from "../download_util";

export function downloadQRCode(pointId) {
  console.log("onDownloadQRCode");
  const svg = document.getElementById("qr-code");
  // const canvas = document.getElementById("qr-code");
  //
  const svgString = new XMLSerializer().serializeToString(svg);
  const svgBlob = new Blob([svgString], {
    type: "image/svg+xml;charset=utf-8",
  });
  const url = window.URL.createObjectURL(svgBlob);
  const image = new Image();
  image.addEventListener("load", () => {
    console.log("onload", image);
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0);
    window.URL.revokeObjectURL(url);

    const imgURI = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const link = document.createElement("a");
    link.href = imgURI;
    link.download = `${pointId}_qr_code.png`;
    link.click();
  });
  image.src = url;
}

export function siteMetaDataDownload(
  pointId,
  coordinates,
  locationData,
  wellData,
  tokenInfo,
) {
  console.log("onDownloadSiteMetaData");
  let header = [
    "PointID",
    "Latitude",
    "Longitude",
    "Elevation (ft asl)",
    "Elevation Method",
    "Easting",
    "Northing",
    "OSE Well ID",
    "OSE Well Tag",
    "Hole Depth (ft bgs)",
    "Well Depth (ft bgs)",
    "Casing Diameter (in)",
    "Casing Depth (ft bgs)",
    "Casing Description",
  ];
  let row = [
    pointId,
    coordinates.latitude,
    coordinates.longitude,
    mToFt(coordinates.elevation),
    locationData["elevation_method"],
    locationData["Easting"],
    locationData["Northing"],
    wellData.OSWellID,
    wellData.OSWellTag,
    wellData.HoleDepth,
    wellData.WellDepth,
    wellData.CasingDiameter ? (wellData.CasingDiameter * 12).toFixed(3) : "",
    wellData.CasingDepth,
    wellData.CasingDescription,
  ];

  if (tokenInfo?.access_token) {
    header.push("Site Names");
    header.push("Public Release");
    row.push(locationData["SiteNames"]);
    row.push(locationData["PublicRelease"] ? "Yes" : "No");
  }

  downloadCSV(`${pointId}_site_metadata.csv`, [row], header);
}

export function waterLevelsDownload(pointId, data) {
  console.log("onDownloadWaterLevels");

  let header = [
    "Measurement Date",
    "Measurement Time",
    "Water Level (ft bgs)",
    "Level Status",
    "Measuring Agency",
    "Data Source",
  ];

  let rows = data.map((item) => {
    return [
      item.DateMeasured,
      item.TimeMeasured,
      item.DepthToWaterBGS,
      item.LevelStatus,
      item.MeasuringAgency,
      item.DataSource,
      item.DataQuality,
      item.MeasurementMethod,
    ];
  });
  downloadCSV(`${pointId}_water_levels.csv`, rows, header);
}
// ============= EOF =============================================
