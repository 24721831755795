// ===============================================================================
// Copyright 2024 Ashish Kodam
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================


import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export default function ScreenTable({ value }) {
    return (
        <DataTable
            value={value}
            stripedRows
            // paginator
            rows={10}
            // rowsPerPageOptions={[5, 10, 25, 50]}
            className={"compact-table"}
            size={"small"}
        >
            <Column
                field={"interval"}
                headerClassName={"small-header"}
                header={"Interval"}
            ></Column>
            <Column
                field={"ScreenTop"}
                headerClassName={"small-header"}
                header={"Top (ft bgs)"}
            ></Column>
            <Column
                field={"ScreenBottom"}
                headerClassName={"small-header"}
                header={"Bottom (ft bgs)"}
            ></Column>
            <Column
                field={"ScreenDescription"}
                headerClassName={"small-header"}
                header={"Description"}
            ></Column>
        </DataTable>
    );
}
// ============= EOF =============================================