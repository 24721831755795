// ===============================================================================
// Copyright 2024 Ashish Kodam
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

export default function OwnerInfoComponent({ owner }) {

  return (
    <div className="relative text-sm">
      <div className="flex flex-wap-wrap flex-direction-row">
        <div className="col-4">
          <label htmlFor="Owner Key" className="font-bold">
            Owner Key
          </label>
          <div className={"pt-1"}> {owner?.OwnerKey}</div>
        </div>
        <div className="col-4 md:col-4">
          <label htmlFor="First Name" className="font-bold block">
            First Name
          </label>
          <div className={"pt-1"}>{owner?.FirstName}</div>
        </div>
        <div className="col-4 md:col-4">
          <label htmlFor="Last Name" className="font-bold block">
            Last Name
          </label>
          <div className={"pt-1"}>{owner?.LastName}</div>
        </div>
      </div>
      <div className="flex flex-wap-wrap flex-direction-row">
        <div className="col-4"></div>
        <div className="col-4 md:col-4">
          <label htmlFor="Secondary First Name" className="font-bold block">
            Secondary First Name
          </label>
          <div className={"pt-1"}>{owner?.SecondFirstName}</div>
        </div>
        <div className="col-4 md:col-4">
          <label htmlFor="Secondary Last Name" className="font-bold block">
            Secondary Last Name
          </label>
          <div className={"pt-1"}>{owner?.SecondLastName}</div>
        </div>
      </div>
      <h4 className="col-12 surface-200">Contact Details</h4>
      <div className="flex flex-wap-wrap flex-direction-row">
        <div className="col-4 md:col-4">
          <label htmlFor="Email" className="font-bold block">
            Email
          </label>
          <div className={"pt-1"} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}> {owner?.Email}</div>
        </div>
        <div className="col-4 md:col-4">
          <label htmlFor="Phone Number" className="font-bold block">
            Phone Number
          </label>
          <div className={"pt-1"} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
              <a href={`tel:${owner?.Phone}`}>
              {owner?.Phone}
              </a>
            </div>
        </div>
        <div className="col-4 md:col-4">
          <label htmlFor="Cell Phone Number" className="font-bold block">
            Cell Phone Number
          </label>
          <div className={"pt-1"} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
            <a href={`tel:${owner?.CellPhone}`}>
              {owner?.CellPhone}
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-wap-wrap flex-direction-row">
        <div className="col-4 md:col-4">
          <label htmlFor="Email" className="font-bold block">
            Secondary Email
          </label>
          <div className={"pt-1"} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}> {owner?.SecondCtctEmail}</div>
        </div>
        <div className="col-4 md:col-4">
          <label htmlFor="Phone Number" className="font-bold block">
            Secondary Phone Number
          </label>
          <div className={"pt-1"} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}> {owner?.SecondCtctPhone}</div>
        </div>
      </div>
      <h4 className="col-12 surface-200">Address</h4>
      <div className="flex flex-wap-wrap flex-direction-row">
        <div className="col-6 md:col-6">
          <label htmlFor="Mailing Adress" className="font-bold block">
            Mailing Address
          </label>
          <div className={"pt-1"}>
            <span>{owner?.MailingAddress}</span>
            <br />
            <span>{owner?.MailCity}</span>
            <br />
            <span>{owner?.MailState}</span>
            <br />
            <span>{owner?.MailZipCode}</span>
          </div>
        </div>
        <div className="col-6 md:col-6">
          <label htmlFor="Physical Address" className="font-bold block">
            Physical Address
          </label>
          <div className={"pt-1"}>
            <span>{owner?.PhysicalAddress}</span>
            <br />
            <span>{owner?.PhysicalCity}</span>
            <br />
            <span>{owner?.PhysicalState}</span>
            <br />
            <span>{owner?.PhysicalZipCode}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
// ============= EOF =============================================
