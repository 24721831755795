// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import STThings from "./STThings";
import STLocations from "./STLocations";
import STDatastreams from "./STDatastreams";
import { Panel } from "primereact/panel";
import { Card } from "primereact/card";

export default function STManager() {
  return (
    <div className={"grid justify-content-center"}>
      <Card className={"col-12 m-3 md:col-10 bg-blue-50"}>
        <h2>ST Manager</h2>
        <p>ST Manager is a tool to manage the SensorThings API</p>
        <p>
          SensorThings root url:{" "}
          <a href={"https://st2.newmexicowaterdata.org/FROST-Server/v1.1/"}>
            https://st2.newmexicowaterdata.org/FROST-Server/v1.1/
          </a>
        </p>
      </Card>
      <Card className={"col-12 m-3 md:col-10 bg-blue-50"}>
        <STDatastreams />
      </Card>
      {/*<Panel header={"Locations"}>*/}
      {/*  <STLocations />*/}
      {/*</Panel>*/}
      {/*<Panel header={"Things"}>*/}
      {/*  <STThings />*/}
      {/*</Panel>*/}
    </div>
  );
}
// ============= EOF =============================================
