// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Card } from "primereact/card";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

export default function STOnboarder() {
  const [datastreamNameOptions, setDatastreamNameOptions] = useState([
    [
      { datastreamName: "Groundwater Levels" },
      { datastreamName: "Groundwater Elevations" },
    ],
  ]);
  const [units, setUnitsOptions] = useState([{ unitofmeasurement: "ft" }]);
  const handleSubmit = (values) => {
    console.log(values);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      latitude: "",
      longitude: "",
      elevation: "",
      thingName: "",
      sensorName: "",
      datastreamName: "",
      unitofmeasurement: "",
    },
    onSubmit: handleSubmit,
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    switch (formik.values.thingName.name) {
      case "Water Well":
        setDatastreamNameOptions([
          { datastreamName: "Groundwater Levels" },
          { datastreamName: "Groundwater Elevations" },
        ]);
        setUnitsOptions([
          { unitofmeasurement: "ft" },
          { unitofmeasurement: "m" },
        ]);
        break;
      case "Stream Gauge":
        setDatastreamNameOptions([{ datastreamName: "Stream Flow" }]);
        break;
      default:
        setDatastreamNameOptions([]);
    }
  }, [formik.values.thingName]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="p-fluid">
        <Card title={"Location"}>
          <div className="field">
            <span className="p-float-label">
              <InputText
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                autoFocus
                className={classNames({
                  "p-invalid": isFormFieldValid("name"),
                })}
              />
              <label
                htmlFor="name"
                className={classNames({ "p-error": isFormFieldValid("name") })}
              >
                Name*
              </label>
            </span>
            {getFormErrorMessage("name")}
          </div>
          <div className="field">
            <span className="p-float-label">
              <InputText
                id="latitude"
                name="latitude"
                value={formik.values.latitude}
                onChange={formik.handleChange}
                autoFocus
                className={classNames({
                  "p-invalid": isFormFieldValid("latitude"),
                })}
              />
              <label
                htmlFor="latitude"
                className={classNames({
                  "p-error": isFormFieldValid("latitude"),
                })}
              >
                Latitude*
              </label>
            </span>
            {getFormErrorMessage("latitude")}
          </div>
          <div className="field">
            <span className="p-float-label">
              <InputText
                id="longitude"
                name="longitude"
                value={formik.values.longitude}
                onChange={formik.handleChange}
                autoFocus
                className={classNames({
                  "p-invalid": isFormFieldValid("longitude"),
                })}
              />
              <label
                htmlFor="longitude"
                className={classNames({
                  "p-error": isFormFieldValid("longitude"),
                })}
              >
                Longitude*
              </label>
            </span>
            {getFormErrorMessage("longitude")}
          </div>
          <div className="field">
            <span className="p-float-label">
              <InputText
                id="elevation"
                name="elevation"
                value={formik.values.elevation}
                onChange={formik.handleChange}
                autoFocus
                className={classNames({
                  "p-invalid": isFormFieldValid("elevation"),
                })}
              />
              <label
                htmlFor="elevation"
                className={classNames({
                  "p-error": isFormFieldValid("elevation"),
                })}
              >
                Elevation
              </label>
            </span>
            {getFormErrorMessage("elevation")}
          </div>
        </Card>
        <Card title={"Thing"}>
          <div className="field">
            <span className="p-float-label">
              <Dropdown
                id="thingName"
                name="thingName"
                value={formik.values.thingName}
                onChange={formik.handleChange}
                options={[{ name: "Water Well" }, { name: "Stream Gauge" }]}
                optionLabel="name"
              />
              <label htmlFor="thingName">Name</label>
            </span>
          </div>
        </Card>
        <Card title={"Sensor"}>
          <div className="field">
            <span className="p-float-label">
              <Dropdown
                id="sensorName"
                name="sensorName"
                value={formik.values.sensorName}
                onChange={formik.handleChange}
                options={[
                  { sensorName: "Enos" },
                  { sensorName: "Pressure Transducer" },
                ]}
                optionLabel="sensorName"
              />
              <label htmlFor="sensorName">Name</label>
            </span>
            {getFormErrorMessage("sensorName")}
          </div>
        </Card>
        <Card title={"Datastream"}>
          <div className="field">
            <span className="p-float-label">
              <Dropdown
                id="datastreamName"
                name="datastreamName"
                value={formik.values.datastreamName}
                onChange={formik.handleChange}
                options={datastreamNameOptions}
                optionLabel="datastreamName"
              />
              <label htmlFor="datastreamName">Name</label>
            </span>
          </div>
          <div className="field">
            <span className="p-float-label">
              <Dropdown
                id="unitofmeasurement"
                name="unitofmeasurement"
                value={formik.values.unitofmeasurement}
                onChange={formik.handleChange}
                options={units}
                optionLabel="unitofmeasurement"
              />
              <label htmlFor="unitofmeasurement">Unit of Measurement</label>
            </span>
          </div>
        </Card>
        <Card>
          <Button type="submit" label="Submit" className="mt-2" />
        </Card>
      </form>
    </div>
  );
}
// ============= EOF =============================================
