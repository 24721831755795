// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";
import { settings } from "../../settings";

export default function InputControl({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedParameter,
  setSelectedParameter,
}) {
  const [parameters, setParameters] = useState();

  useEffect(() => {
    fetch(settings.die_url + "/parameters")
      .then((res) => res.json())
      .then((data) => {
        setParameters(data);
      });
  }, []);
  return (
    <div>
      <div className={"col"}>
        <label className={"p-3"}>Parameter:</label>
        <Dropdown
          className={"flex mt-1"}
          value={selectedParameter}
          options={parameters}
          onChange={(e) => setSelectedParameter(e.value)}
          optionLabel="name"
          placeholder="Select a Parameter"
        />
      </div>
      <div className={"col"}>
        <label className={"p-3"}>Start Date:</label>
        <Calendar
          vale={startDate}
          onChange={(e) => setStartDate(e.value)}
          showIcon
        />
      </div>
      <div className={"col"}>
        <label className={"p-3"}>End Date:</label>
        <Calendar
          vale={endDate}
          onChange={(e) => setEndDate(e.value)}
          showIcon
        />
      </div>
    </div>
  );
}
// ============= EOF =============================================
