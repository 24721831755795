// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

// A component for adding and editing ST locations

import STLocations from "./STLocations";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import LocationEditor from "./Editors/LocationEditor";
import ThingEditor from "./Editors/ThingEditor";
import SimpleEditor from "./Editors/SimpleEditor";

export default function STLocationEditor() {
  const [selectedLocation, setSelectedLocation] = useState(null);
  return (
    <div>
      {/*<Card>*/}
      {/*  <STLocations*/}
      {/*    selectedLocation={selectedLocation}*/}
      {/*    setSelectedLocation={setSelectedLocation}*/}
      {/*    showMap={true}*/}
      {/*  />*/}
      {/*</Card>*/}
      {/*<Card>*/}
      {/*  <LocationEditor selectedLocation={selectedLocation} />*/}
      {/*</Card>*/}
      <Card>
        <SimpleEditor />
      </Card>
    </div>
  );
}
// ============= EOF =============================================
