// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { confirmDialog } from "primereact/confirmdialog";

export const home = {
  id: "home",
  label: "Home",
  icon: "pi pi-fw pi-home",
  command: () => {
    window.location.href = "/";
  },
};

export const tools = {
  id: "tools",
  label: "Tools",
  icon: "pi pi-fw pi-wrench",
  items: [
    {
      id: "site_cross_ref",
      label: "Site Cross-Ref. Service",
      icon: "pi pi-fw pi-search",
      command: () => {
        window.location.href = "/site_cross_ref";
      },
    },
    {
      id: "die",
      label: "Data Integration Engine",
      icon: "pi pi-fw pi-cog",
      command: () => {
        window.location.href = "/die";
      },
    },
    {
      id: "st_viewer",
      label: "SensorThings Viewer",
      icon: "pi pi-fw pi-eye",
      command: () => {
        window.location.href = "/st";
      },
    },
  ],
};
export const groundwater = {
  id: "groundwater",
  label: "Groundwater",
  icon: "pi pi-fw pi-map",
  command: () => {
    window.location.href = "/groundwater";
  },
};

export const maps = {
  id: "maps",
  label: "Maps",
  icon: "pi pi-fw pi-map",
  // command: () => {
  //   window.location.href = "/dashboard";
  // },
  items: [
    // {
    //   label: "Discover Tool",
    //   icon: "pi pi-fw pi-map",
    //   command: () => {
    //     window.location.href = "/dashboard";
    //   },
    // },
    {
      id: "groundwater",
      label: "Groundwater",
      icon: "pi pi-fw pi-map",
      command: () => {
        window.location.href = "/groundwater";
      },
    },
    // {
    //   id: "pws",
    //   label: "Public Water Systems",
    //   icon: "pi pi-fw pi-cloud",
    //   command: () => {
    //     window.location.href = "/pws";
    //   },
    // },
    // disable for now until Surface Water is ready
    // {
    //   label: "Surface Water",
    //   icon: "pi pi-fw pi-map",
    //   command: () => {
    //     window.location.href = "/surfacewater";
    //   },
    // },
  ],
};
export const documentation = {
  id: "documentation",
  label: "Docs",
  icon: "pi pi-fw pi-question-circle",
  command: () => {
    window.location.href = "/docs";
  },
};

export const wells = {
  id: "wells",
  label: "Wells",
  icon: "pi pi-fw pi-exclamation-triangle",
  command: () => {
    window.location.href = "/wells";
  },
};

export const collabnet = {
  id: "collabnet",
  label: "CollabNet",
  icon: "pi pi-fw pi-users",
  command: () => {
    window.location.href = "/collabnet";
  },
};

const accept = () => {
  window.location.href = "https://catalog.newmexicowaterdata.org";
  // localStorage.setItem("hideDataCatalogDecision", rememberChecked.toString());
};

export const confirm = () => {
  confirmDialog({
    message: (
      <div>
        <p>
          You are about to leave this website and go to NMWDI's Data Catalog.
          Are you sure you want to continue?
        </p>
        {/* <input type="radio" checked={rememberChecked} onChange={handleRememberChange} /> Remember me */}
        {/*<Checkbox*/}
        {/*  inputId="checbox"*/}
        {/*  // onChange={handleRememberChange}*/}
        {/*  // onChange={(e) => setRememberChecked(e.checked)}*/}
        {/*  // checked={rememberChecked}*/}
        {/*/>*/}
        {/*<label htmlFor="checbox" className="ml-2">*/}
        {/*  Remember my response*/}
        {/*</label>*/}
      </div>
    ),

    header: "Confirm Navigation",
    icon: "pi pi-info-circle",
    position: "top",
    accept,
  });
};
// ============= EOF =============================================
