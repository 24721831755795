// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useState } from "react";

export default function NameSearchControl({ onSearch }) {
  const [name, setName] = useState("");

  return (
    <div className={"grid"}>
      <div className={"col-8 p-0 mr-0"}>
        <InputText
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Search by Name"
        />
      </div>
      <div className={"col-3 p-0 ml-1 mr-0"}>
        <Button onClick={(e) => onSearch(name)}>Search</Button>
      </div>
    </div>
  );
}
// ============= EOF =============================================
