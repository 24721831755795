// ===============================================================================
// Copyright 2023 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import "mapbox-gl/dist/mapbox-gl.css";
import "./LocationDetail.css";

import { useParams } from "react-router-dom";
import { Panel } from "primereact/panel";
import { useEffect, useMemo, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Map, { Layer, Marker, Popup, Source } from "react-map-gl";
import { useFiefTokenInfo } from "@fief/fief/react";
import { decimalToDMS, getPhoto, mToFt, nmbgmr_getJson } from "../../util";
import { settings } from "../../settings";
import { RadioButton } from "primereact/radiobutton";
import { Galleria } from "primereact/galleria";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import { Chip } from "primereact/chip";
import WaterChem from "./WaterChem";
import { Button } from "primereact/button";
// import MasterUnionWaterChem from "./MasterUnionWaterChem";
import { Dropdown } from "primereact/dropdown";
import AMPAPIHydrograph from "./AMPAPIHydrograph";
import { OverlayPanel } from "primereact/overlaypanel";
import WellSchematic from "./WellSchematic";
import ScreenTable from "./WellScreenTable";
import KeyValueTable, {
  makeOSEData,
  OSEKeyValueTable,
  toKeyValueRows,
} from "./KeyValueTable";
import {
  makeExportHeaderTemplate,
  makePanelHeaderTemplate,
  makeHydrographExportHeaderTemplate,
} from "./HeaderTemplates";
import OwnerInfoComponent from "./OwnerInfoComponent";
import * as turf from "@turf/turf";
import { itemTemplate, thumbnailTemplate } from "./Templates";
import {
  downloadQRCode,
  siteMetaDataDownload,
  waterLevelsDownload,
} from "./Download";
import EquipmentTable from "./EquipmentTable";
import QRCode from "react-qr-code";
import OwnersWells from "./OwnersWells";
import LocationEditor from "./LocationEditor";
import { jwtDecode } from "jwt-decode";
import { Card } from "primereact/card";

export default function LocationDetail({ pid }) {
  // if pid is not passed in, then use the pointid from the URL
  const { paramPointId } = useParams();
  const pointId = pid ? pid : paramPointId;

  //Error handling for incorrect pointID
  const [pointIdValid, setPointIdValid] = useState(true);

  {
    /* Start Well Screen Interval Table */
  }
  const [wellScreenIntervals, setWellScreenIntervals] = useState([
    {
      PointID: "",
      counter: null,
      ScreenTop: null,
      ScreenBottom: null,
      ScreenDescription: "",
      Units: "",
    },
  ]);

  {
    /* End Well Screen Interval Table */
  }
  const [hydrographData, setHydrographData] = useState([]);
  const [majorChemistryData, setMajorChemistryData] = useState([]);

  const [hydrographDownloadConfiguration, setHydrographDownloadConfiguration] =
    useState({
      daily: false,
    });

  const tokenInfo = useFiefTokenInfo();
  const [projects, setProjects] = useState([]);
  const [notes, setNotes] = useState("");
  const [waterNotes, setWaterNotes] = useState("");
  const [statusUserNotes, setStatusUserNotes] = useState("");
  const [degreeMinutesSeconds, setDegreeMinutesSeconds] = useState(false);
  const [locationInfo, setLocationInfo] = useState([
    { key: "Latitude", value: "" },
    { key: "Longitude", value: "" },
    { key: "Elevation", value: "" },
  ]);
  const [coordinates, setCoordinates] = useState({
    latitude: 35,
    longitude: -106,
    elevation: 0,
  });
  const [locationData, setLocationData] = useState({});
  const [usgsSiteMetadata, setUsgsSiteMetadata] = useState([{}]);
  const [oseSiteMetadata, setOSESiteMetadata] = useState([{}]);

  const [wellData, setWellData] = useState();
  const [wellInfo, setWellInfo] = useState([
    { key: "Well Depth", value: "" },
    { key: "Well Bore Diameter", value: "" },
  ]);

  const [ownerInfo, setOwnerInfo] = useState({
    FirstName: "",
    LastName: "",
    OwnerKey: "",
    Email: "",
    CellPhone: "",
    Phone: "",
    MailingAddress: "",
    MailCity: "",
    MailState: "",
    MailZipCode: "",
    PhysicalAddress: "",
    PhysicalCity: "",
    PhysicalState: "",
    PhysicalZipCode: "",
    SecondLastName: "",
    SecondFirstName: "",
    SecondCtctEmail: "",
    SecondCtctPhone: "",
  });

  const [photos, setPhotos] = useState([
    {
      src: "https://upload.wikimedia.org/wikipedia/commons/1/11/American_Beaver%2C_tree_cutting.jpg",
      caption: "American Beaver, tree cutting",
    },
    {
      src: "https://upload.wikimedia.org/wikipedia/commons/6/6b/American_Beaver.jpg",
      caption: "Beaver",
    },
    {
      src: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Castor_canadensis1.jpg",
      caption: "Castor canadensis",
    },
    {
      src: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Castor_fiber_eating_in_Eskilstuna%2C_Sweden.jpg",
      caption: "Castor fiber eating in Eskilstuna, Sweden",
    },
  ]);

  const mapRef = useRef(null);

  //  Start state for toggle button to show nearby locations
  const [showNearbyLocations, setShowNearbyLocations] = useState(false);
  const [selectedRadius, setSelectedRadius] = useState("1mi");
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [popupCoordinates, setPopuCoordinates] = useState([0, 0]);
  const [nearbyLocations, setNearbyLocations] = useState({});
  const [nearbyRadiusCircle, setNearbyCircle] = useState({});

  const [infoText, setInfoText] = useState("");
  const infoTextOverlayPanelRef = useRef();
  const [hydrographLoading, setHydrographLoading] = useState(false);
  const [trend, setTrend] = useState();
  const [showLocationEditor, setShowLocationEditor] = useState(false);
  const qrOverlayRef = useRef(null);
  // End

  // const token = jwtDecode(tokenInfo?.access_token);
  // const showLocationEditor = token?.permissions?.includes("well:write");

  const authorized_api_getJson = (url, options = { base: "locations" }) => {
    if (tokenInfo === null || tokenInfo.access_token === undefined) {
      return Promise.resolve(undefined);
    }

    let base = options.base;
    return api_getJson(`${base}/${url}`, { base: "authorized" });
  };

  const api_getJson = (
    url,
    options = { base: "locations", queryparams: null },
  ) => {
    let u;
    if (options.base !== undefined && options.base !== null) {
      if (url !== null && url !== undefined && url.length > 0) {
        u = `${options.base}/${url}`;
      } else {
        u = options.base;
      }
    } else {
      u = url;
    }
    u = `${u}?pointid=${pointId}`;
    if (options?.queryparams !== undefined && options?.queryparams !== null) {
      u = `${u}&${options.queryparams}`;
    }
    return nmbgmr_getJson(u, tokenInfo?.access_token);
  };

  const toast = useRef(null);

  useMemo(() => {
    // useMemo to load the location info.
    // then useEffect with degreeMinutesSeconds as a dependency to switch between decimal and dms

    const handleInfo = (data) => {
      if (data === undefined || data.features.length === 0) {
        //Erorr handling for invalid pointID
        setPointIdValid(false);
        return;
      }
      setPointIdValid(true);
      // console.log("handleInfo", data);
      // console.log("af", data["features"][0]);
      // console.log("af", data["features"][0]["geometry"]);
      let feature = data["features"][0];
      let coordinates = feature["geometry"]["coordinates"];

      setCoordinates({
        latitude: coordinates[1],
        longitude: coordinates[0],
        elevation: coordinates[2],
      });
      setLocationData(feature);
    };

    const handleWells = (data) => {
      setWellData(data);
      let rows = toKeyValueRows(data, [
        "LocationId",
        "WellID",
        "PointID",
        "screens",
      ]);
      setWellInfo(rows);
    };
    const handleWellScreens = (data) => {
      if (data === undefined) {
        return;
      }
      let result = data.map((item, index) => {
        return {
          interval: index + 1,
          ScreenTop: item.ScreenTop,
          ScreenBottom: item.ScreenBottom,
          ScreenDescription: item.ScreenDescription,
        };
      });
      setWellScreenIntervals(result);
    };
    api_getJson("trend", { base: "waterlevels" }).then((data) => {
      console.log("get trend", data);
      setTrend(data);
    });
    
    api_getJson("").then((data) => {
      handleInfo(data);
      //console.log("handleInfo after api", data);
    });
    
    api_getJson("wells", { base: null }).then((data) => {
      handleWells(data);
    });

    api_getJson("wells/screens", { base: null }).then((data) => {
      handleWellScreens(data);
    });

    if (tokenInfo?.access_token) {
      authorized_api_getJson(`owners`).then((data) => {
        console.log("owerna adsf", data);
        setOwnerInfo(data);
      });

      authorized_api_getJson(`photos`).then(async (data) => {
        if (data === undefined) {
          return;
        }

        let photos = await Promise.all(
          data.map(async (photo) => {
            try {
              const resp = await getPhoto(
                photo.OLEPath,
                tokenInfo.access_token,
              );
              return {
                key: photo.OLEPath,
                src: URL.createObjectURL(resp),
                caption: photo.OLEPath,
              };
            } catch (e) {
              console.log("getPhoto error:", e);
            }
          }),
        );

        setPhotos(photos.filter((p) => p !== undefined));
      });

      authorized_api_getJson(`projects`).then((data) => {
        console.debug("projects", data);
        setProjects(data);
      });

      authorized_api_getJson(`notes`).then((data) => {
        console.debug("notes", data);
        setNotes(data?.notes);
        setWaterNotes(data?.water_notes);
        setStatusUserNotes(data?.status_user_notes);
      });
    }

    api_getJson("sitemetadata/usgs")
      .then((data) => {
        if (data !== null && data !== undefined) {
          setUsgsSiteMetadata(toKeyValueRows(data));
        }
      })
      .catch((e) => {
        console.error("public/locations/sitemetadata/usgs", e);
      });

    api_getJson("sitemetadata/ose").then((data) => {
      if (data === undefined || data === null) {
        return;
      }
      if (data["pod"] === null) {
        return;
      }
      // console.log("asdf", data["pod"]);
      setOSESiteMetadata(makeOSEData(data["pod"]));
    });
  }, [pointId]);

  // ============ begin useEffects ============
  useEffect(() => {
    if (tokenInfo?.access_token === undefined) {
      return;
    }
    const token = jwtDecode(tokenInfo?.access_token);
    setShowLocationEditor(token.permissions.includes("well:write"));
  }, [tokenInfo]);

  useEffect(() => {
    let radius;
    switch (selectedRadius) {
      case "1mi":
        radius = 1609.34;
        break;
      case "5mi":
        radius = 8046.72;
        break;
      case "10mi":
        radius = 16093.4;
        break;
      default:
        radius = 1609.34;
    }
    api_getJson("nearby", {
      base: "locations",
      queryparams: `radius=${radius}`,
    }).then((data) => {
      console.log("newqave", data);
      setNearbyLocations(data);
    });
  }, [pointId, selectedRadius]);

  useEffect(() => {
    if (Object.keys(locationData).length === 0) {
      return;
    }
    if (mapRef.current === null) {
      return;
    }
    let center = locationData["geometry"]["coordinates"];
    mapRef.current.setCenter(center);

    let elevation = locationData["geometry"]["coordinates"][2];
    let elevation_ft = mToFt(elevation);

    let lon = center[0];
    let lat = center[1];
    if (degreeMinutesSeconds === true) {
      lat = decimalToDMS(lat, "lat");
      lon = decimalToDMS(lon, "lon");
    } else {
      lat = lat.toFixed(6);
      lon = lon.toFixed(6);
    }
    let properties = locationData["properties"];

    let lonlat_datum = `(${properties["lonlat_datum"]})` || "";
    let elevation_datum = `(${properties["altitude_datum"]})` || "";
    let utm_datum = `(${properties["utm_datum"]})` || "";
    let locationInfo = [
      { key: "Lat/Lon", value: `${lat}, ${lon} ${lonlat_datum}` },
      {
        key: "Elevation ft",
        value: `${elevation_ft} ${elevation_datum}`,
      },
      { key: "Elevation Method", value: properties["elevation_method"] },
      {
        key: "UTM",
        value: `${properties["easting"]} ${properties["northing"]} ${utm_datum}`,
      },
      { key: "USGS Site No.", value: properties["site_id"] },
    ];

    console.log("public release", locationData);
    if (tokenInfo?.access_token) {
      locationInfo.push({
        key: "Site Names",
        value: properties["site_names"],
      });
      locationInfo.push({
        key: "Public Release",
        value: properties["public_release"] ? "Yes" : "No",
      });
    }
    console.log("locationData", locationData);
    setLocationInfo(locationInfo);
  }, [degreeMinutesSeconds, locationData]);

  useEffect(() => {
    const miles = parseInt(selectedRadius.slice(0, -2));
    const circle = turf.circle(
      [coordinates.longitude, coordinates.latitude],
      miles,
      { steps: 60, units: "miles" },
    );
    if (mapRef.current !== null) {
      mapRef.current.fitBounds(turf.bbox(circle), {
        padding: 10,
      });
    }
    setNearbyCircle(turf.featureCollection([circle]));
  }, [pointId, showNearbyLocations, selectedRadius, coordinates]);
  // ============ end useEffects ============

  // ============ begin download handlers ============
  const onDownloadSiteMetaData = () => {
    siteMetaDataDownload(
      pointId,
      coordinates,
      locationData,
      wellData,
      tokenInfo,
    );
  };

  const onDownloadWaterLevels = async () => {
    let data;

    if (hydrographDownloadConfiguration.daily === true) {
      // setButtonLoading(true);
      data = await api_getJson("waterlevels/daily", { base: null });
      // setButtonLoading(false);
    } else if (hydrographData.length === 0) {
      // setButtonLoading(true);
      data = await api_getJson("waterlevels", { base: null });
      // setButtonLoading(false);
    } else {
      data = hydrographData;
    }
    waterLevelsDownload(pointId, data);
  };

  // const onDownloadWaterChemistry = async () => {
  //   console.log("onDownloadWaterChemistry");
  //   let data;
  //   if (majorChemistryData.length === 0) {
  //     setButtonLoading(true);
  //     data = await assembleMajorChemistry(pointId);
  //   } else {
  //     data = majorChemistryData;
  //   }
  //   setButtonLoading(false);
  //
  //   let header = MAJOR_CHEM_FIELDS.map((field) => {
  //     return field.key;
  //   });
  //   let rows = data.map((item) => {
  //     return MAJOR_CHEM_FIELDS.map((field) => {
  //       return item[field.key];
  //     });
  //   });
  //   downloadCSV(`${pointId}_water_chemistry.csv`, rows, header);
  // };
  // ============ end download handlers ============

  // const notImplemented = (summary) => {
  //   toast.current.show({
  //     severity: "warn",
  //     summary: summary,
  //     detail: "This feature is not yet implemented",
  //     life: 3000,
  //   });
  // };

  // Start of Toggle show nearby loactions
  const mapHeaderTemplate = (options) => {
    return (
      <div className={options.className}>
        <div>{options.titleElement}</div>
        <div className={`flex`}>
          <Button
            tooltip={"Show/Hide Nearby Locations"}
            tooltipOptions={{ showDelay: 500 }}
            icon={showNearbyLocations ? "pi pi-eye-slash" : "pi pi-eye"}
            onClick={(e) => setShowNearbyLocations(!showNearbyLocations)}
            title={showNearbyLocations ? "Hide" : "Show"}
            className="p-button-outlined toggle-button"
          />
          <div style={{ display: showNearbyLocations ? "block" : "none" }}>
            <Dropdown
              tooltip={'Select a "Nearby" Radius'}
              tooltipOptions={{ showDelay: 500 }}
              value={selectedRadius}
              options={[
                { label: "1mi", value: "1mi" },
                { label: "5mi", value: "5mi" },
                { label: "10mi", value: "10mi" },
              ]}
              onChange={(e) => setSelectedRadius(e.value)}
              // className={classNames({ "p-inputtext": !showNearbyLocations })}
            />
          </div>
        </div>
      </div>
    );
  };

  const getCurrentPoint = (e) => {
    // const sourcenames = sources.map((s) => s.tag);
    const features = mapRef.current.queryRenderedFeatures(e.point);
    return features.find((f) => f.layer.id === "nearbylocations");
  };
  const onMouseClick = (e) => {
    const selected_point = getCurrentPoint(e);
    console.log("clicked", selected_point);
    if (selected_point === undefined) {
      return;
    }
    window.open("/location/" + selected_point.properties.name, "_blank");
  };
  const onMouseMove = (e) => {
    const selected_point = getCurrentPoint(e);
    if (selected_point === undefined) {
      mapRef.current.getCanvas().style.cursor = "grab";
      setShowPopup(false);
      return;
    }
    mapRef.current.getCanvas().style.cursor = "pointer";
    setPopuCoordinates(e.lngLat);

    setPopupData([
      { key: "Name", value: selected_point.properties.name },
      // { key: "Source", value: selected_point.source },
    ]);
    setShowPopup(true);
  };

  const onUSGSInfo = (e) => {
    setInfoText(
      "The data displayed in this table are directly from the USGS. NMWDI does not verify the accuracy " +
        "of this data. Please refer to the USGS for more information.",
    );
    infoTextOverlayPanelRef.current.toggle(e);
  };

  const onOSEInfo = (e) => {
    setInfoText(
      "The data displayed in this table are directly from the NM OSE. NMWDI does not verify the accuracy of" +
        " this data. Please refer to the OSE for more information.",
    );
    infoTextOverlayPanelRef.current.toggle(e);
  };

  if (!pointIdValid) {
    return (
      <div className="m-3">
        <div className={"flex justify-content-center flex-wrap p-6"}>
        <Message severity="error" text={`Invalid PointID ${pointId}, Please Try Again`} />
        </div>
      </div>
    );
  }

  return (
    <div className={"m-3"}>
      <Toast ref={toast} />
      <Toast ref={toast} position="top-right" />

      <div className={"flex justify-content-center flex-wrap"}>
        <div className={""}>
          <h1>Location Detail {pointId}</h1>
        </div>
        <div className={"p-2 flex align-items-center"}>
          <OverlayPanel ref={qrOverlayRef} showCloseIcon>
            <div>
              <p className={"max-w-17rem"}>
                Use the QR code to share this location with others. The QR code
                will link to {pointId} <code>Location Detail</code> page.
              </p>
              <div>
                <QRCode id={"qr-code"} value={window.location.href} />
              </div>
              <div>
                <Button
                  icon={"pi pi-download"}
                  onClick={(e) => downloadQRCode(pointId)}
                >
                  Download QR Code
                </Button>
              </div>
            </div>
          </OverlayPanel>
          <Button
            onClick={(e) => {
              qrOverlayRef.current.toggle(e);
            }}
            icon={"pi pi-qrcode"}
          >
            Share
          </Button>
          
          {/* Add auth check to display directions button */}
          {tokenInfo?.access_token && (
          <Button
            className={"m-1"}
            severity={"help"}
            onClick={(e) => {
              window.open(
                `https://www.google.com/maps/dir//${coordinates.latitude},${coordinates.longitude}`,
                "_blank",
              );
            }}
          >
            Directions
          </Button>
          )}

        </div>
      </div>
      <div className={"grid"}>
        <div className={"col-12"}>
          <Panel
            headerTemplate={makeHydrographExportHeaderTemplate(
              onDownloadWaterLevels,
              hydrographDownloadConfiguration,
              setHydrographDownloadConfiguration,
            )}
            header={
              <div>
                <span className={"panelicon pi pi-chart-line"} />
                Hydrograph
              </div>
            }
            collapsed={false}
            toggleable
          >
            <AMPAPIHydrograph
              pointId={pointId}
              setHydrographData={setHydrographData}
              loading={hydrographLoading}
              setLoading={setHydrographLoading}
              showTable={true}
            />
          </Panel>
        </div>
      </div>
      <div className={"grid"}>
        <div className={"col-12 md:col-6 lg:col-4"}>
          <Panel
            header={
              <div>
                <span className={"panelicon pi pi-map"} />
                Map
              </div>
            }
            headerTemplate={mapHeaderTemplate}
          >
            <Map
              ref={mapRef}
              mapboxAccessToken={settings.mapbox.token}
              initialViewState={{
                longitude: coordinates.longitude,
                latitude: coordinates.latitude,
                zoom: 10,
              }}
              mapStyle={"mapbox://styles/mapbox/satellite-streets-v11"}
              style={{ width: "100%", height: "353px" }}
              onMouseMove={onMouseMove}
              onClick={onMouseClick}
            >
              {showNearbyLocations === true && (
                <Source
                  id={"nearbylocations"}
                  key={"nearbylocations"}
                  type="geojson"
                  data={nearbyLocations}
                >
                  <Layer
                    id={"nearbylocations"}
                    type="symbol"
                    paint={{
                      "circle-radius": 4,
                      "circle-color": "yellow",
                      "circle-stroke-color": "black",
                      "circle-stroke-width": 1,
                    }}
                    layout={{
                      "text-field": ["get", "name"],
                      "icon-image": "marker-15",
                      "text-size": 14,
                      "text-allow-overlap": true,
                      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                      "text-variable-anchor": [
                        "top",
                        "bottom",
                        "left",
                        "right",
                      ],
                      "text-radial-offset": 0.5,
                    }}
                    // layout={{ visibility: layerVisibility[s.tag] }}
                  />
                </Source>
              )}
              {showNearbyLocations && (
                <Source
                  id={"nearbyradius"}
                  type={"geojson"}
                  data={nearbyRadiusCircle}
                >
                  <Layer
                    id={"nearbyradius"}
                    type={"line"}
                    paint={{
                      "line-color": "blue",
                      "line-width": 2,
                      "line-dasharray": [2, 2],
                      "line-offset": -2,
                    }}
                  />
                  <Layer
                    id={"nearbyradius-fill"}
                    type={"fill"}
                    paint={{
                      // "line-color": "red",
                      // "line-width": 2,
                      "fill-color": "white",
                      "fill-opacity": 0.25,
                      // "fill-outline-color": "black",
                    }}
                  />
                </Source>
              )}
              <Marker
                latitude={coordinates.latitude}
                longitude={coordinates.longitude}
              >
                {/*<div*/}
                {/*  style={{*/}
                {/*    width: selectedRadius * 2,*/}
                {/*    height: selectedRadius * 2,*/}
                {/*    borderRadius: "50%",*/}
                {/*  }}*/}
                {/*/>*/}
              </Marker>
              {/*{showPopup && (*/}
              {/*  <Popup*/}
              {/*    latitude={popupCoordinates.lat}*/}
              {/*    longitude={popupCoordinates.lng}*/}
              {/*    maxWidth={500}*/}
              {/*    closeButton={false}*/}
              {/*  >*/}
              {/*    <DataTable*/}
              {/*      value={popupData}*/}
              {/*      size={"small"}*/}
              {/*      stripedRows*/}
              {/*      showGridlines*/}
              {/*      header={null}*/}
              {/*      className={"popupTable"}*/}
              {/*    >*/}
              {/*      <Column field={"key"} header={"Attribute"}></Column>*/}
              {/*      <Column field={"value"} header={"Value"}></Column>*/}
              {/*    </DataTable>*/}
              {/*  </Popup>*/}
              {/*)}*/}
            </Map>
          </Panel>
        </div>
        <div className={"col-12 md:col-6 lg:col-3"}>
          <Panel
            headerTemplate={makeExportHeaderTemplate(onDownloadSiteMetaData)}
            header={
              <div>
                <span className={"panelicon pi pi-flag"} />
                Location Info
              </div>
            }
          >
            <div style={{ padding: "0px 10px 20px 10px" }}>
              <div className={"flex align-items-center hradio"}>
                <RadioButton
                  inputId={"degree_minutes_seconds"}
                  value={"Degree Minutes Seconds"}
                  onChange={(e) => setDegreeMinutesSeconds(true)}
                  checked={degreeMinutesSeconds === true}
                />
                <label htmlFor="degree_minutes_seconds" className={"ml-1 mr-2"}>
                  Degree Minutes Seconds
                </label>
                <RadioButton
                  inputId={"decimal_degree"}
                  value={"Decimal Degree"}
                  onChange={(e) => setDegreeMinutesSeconds(false)}
                  checked={degreeMinutesSeconds === false}
                />
                <label htmlFor="decimal_degree" className="ml-1">
                  Decimal Degree
                </label>
              </div>
            </div>
            <KeyValueTable value={locationInfo} />
          </Panel>
          {tokenInfo && (
            <Panel header={"Projects"}>
              <div className={"flex align-items-center flex-wrap"}>
                {projects
                  ?.filter(
                    (value, index, self) =>
                      index ===
                      self.findIndex(
                        (t) => t.place === value.place && t.name === value.name,
                      ),
                  )
                  .map((project) => {
                    // console.log("asdfsafffff", project);
                    let className = "";
                    switch (project.ProjectName) {
                      case "NGWMN":
                        className = "ngwmn";
                        break;
                      case "Water Level Network":
                        className = "collabnet";
                        break;
                      default:
                        className = "";
                    }
                    return (
                      <Chip
                        key={project.ProjectName}
                        label={project.ProjectName}
                        className={`mr-2 mb-2 ${className}`}
                      />
                    );
                  })}
              </div>
            </Panel>
          )}
        </div>
        <div className={"col-12 md:col-6 lg:col-2"}>
          <Panel header={"Schematic"}>
            <WellSchematic
              screens={wellScreenIntervals}
              wellData={wellData}
              waterLevelData={hydrographData}
              loading={hydrographLoading}
              trend={trend}
            />
          </Panel>
        </div>
        <div className={"col-12 md:col-6 lg:col-3"}>
          <Panel header={"Well Info"}>
            <KeyValueTable value={wellInfo} />

            {tokenInfo?.access_token && (
              <div>
                <Panel collapsed toggleable header={"Notes"}>
                  <InputTextarea
                    className={"text-xs"}
                    style={{ height: "50px", width: "100%" }}
                    value={notes}
                  />
                </Panel>
                <Panel collapsed toggleable header={"Water Notes"}>
                  <InputTextarea
                    className={"text-xs"}
                    style={{ height: "50px", width: "100%" }}
                    value={waterNotes}
                  />
                </Panel>
                <Panel toggleable collapsed header={"Status User Notes"}>
                  <InputTextarea
                    className={"text-xs"}
                    style={{ height: "50px", width: "100%" }}
                    value={statusUserNotes}
                  />
                </Panel>
              </div>
            )}
            {/* Start Well Screen Interval Table */}
            <Panel header={"Screens"} toggleable collapsed={true}>
              <ScreenTable value={wellScreenIntervals} />
            </Panel>
            {/* End Well Screen Interval Table */}
          </Panel>
        </div>
      </div>
      {showLocationEditor && (
        <div className={"flex flex-row"}>
          <div className={"col-12"}>
            <Panel header={"Location Editor"} collapsed toggleable>
              <LocationEditor wellData={wellData} />
            </Panel>
          </div>
        </div>
      )}
    
      {tokenInfo?.access_token && (
        <div>
          <div className={"grid"}>
            <div className={"col-12 md:col-6 lg:col-6"}>
              <Panel
                header={
                  <div>
                    <span className={"panelicon pi pi-cog"} />
                    Equipment
                  </div>
                }
                collapsed={false}
                toggleable
              >
                <EquipmentTable pointId={pointId} />
              </Panel>
            </div>
            <div className={"col-12 md:col-6 lg:col-6"}>
              <Panel
                header={
                  <div>
                    <span className={"panelicon pi pi-user"} />
                    Owner
                  </div>
                }
                collapsed={false}
                toggleable
              >
                <OwnerInfoComponent owner={ownerInfo} />
              </Panel>
            </div>
          </div>
          <div className={"grid"}>
            <div className={"col-12"}>
              <Panel header={"Owner's Wells"} collapsed toggleable>
                <OwnersWells pointId={pointId} />
              </Panel>
            </div>
          </div>
        </div>
      )}
      <div className={"grid"}>
        <div className={"col-12"}>
          <Panel header={"Major Chemistry"} toggleable collapsed>
            <WaterChem
              pointID={pointId}
              setMajorChemistryData={setMajorChemistryData}
            />
          </Panel>
        </div>
      </div>
      {/*<div className={"flex flex-row"}>*/}
      {/*  <div className={"col-12"}>*/}
      {/*    <Panel header={"Master Union Water Chemistry"} toggleable collapsed>*/}
      {/*      <MasterUnionWaterChem pointID={pointId} />*/}
      {/*    </Panel>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {tokenInfo && (
        <div className={"grid"}>
          <div className={"col-12"}>
            <Panel
              header={
                <div>
                  <span className={"panelicon pi pi-camera"} />
                  Photos
                </div>
              }
              toggleable
            >
              <Galleria
                thumbnailsPosition="top"
                circular
                numVisible={5}
                value={photos}
                item={itemTemplate}
                thumbnail={thumbnailTemplate}
              />
              {/*<div className={"photo_carousel"}>*/}
              {/*  <Carousel*/}
              {/*    circular*/}
              {/*    value={photos}*/}
              {/*    numVisible={3}*/}
              {/*    numScroll={3}*/}
              {/*    verticalViewPortHeight="360px"*/}
              {/*    itemTemplate={photoTemplate}*/}
              {/*  />*/}
              {/*</div>*/}
            </Panel>
          </div>
        </div>
      )}

      <div className={"grid"}>
        <div className={"col-12 md:col-6 lg:col-6"}>
          <OverlayPanel className={"w-4"} ref={infoTextOverlayPanelRef}>
            <div>{infoText}</div>
          </OverlayPanel>
          <Panel
            header={"USGS Site Metadata"}
            toggleable
            headerTemplate={makePanelHeaderTemplate(onUSGSInfo)}
          >
            <KeyValueTable value={usgsSiteMetadata} />
          </Panel>
        </div>
        <div className={"col-12 md:col-6 lg:col-6"}>
          <Panel
            header={"OSE POD (Point Of Diversion)"}
            toggleable
            headerTemplate={makePanelHeaderTemplate(onOSEInfo)}
          >
            <OSEKeyValueTable value={oseSiteMetadata} />
          </Panel>
        </div>
      </div>
    </div>
  );
}

// ============= EOF =============================================
