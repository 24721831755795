// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Button } from "primereact/button";
import { useFiefTokenInfo } from "@fief/fief/react";
import { useEffect, useRef, useState } from "react";
import { nmbgmr_postJson } from "../../util";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";

export default function LocationEditor({ wellData }) {
  const tokenInfo = useFiefTokenInfo();
  const [monitoringStatus, setMonitoringStatus] = useState([]);
  const [notes, setNotes] = useState("");
  const toast = useRef(null);

  //todo: get this from the API
  const availableMonitoringStatus = [
    { name: "Monitor every six months", code: "6" },
    { name: "Annual water level", code: "A" },
    { name: "Monitoring bi-monthly", code: "B" },
    { name: "Monitoring complete", code: "C" },
    { name: "Datalogger installed", code: "D" },
    { name: "Monitor every 10 years (long-term monitor)", code: "L" },
    { name: "Monitor monthly", code: "M" },
    { name: "Sampling complete", code: "Q" },
    { name: "Reported to NMBGMR bimonthly", code: "R" },
    { name: "Sample well", code: "S" },
    { name: "Water level cannot be measured", code: "X" },
    { name: "Repeat sampling", code: "P" },
    { name: "Wellntel device", code: "W" },
    { name: "Bi-annual (every other year)", code: "N" },
    { name: "Inactive", code: "I" },
    { name: "Data share", code: "H" },
  ];

  const handleEditLocation = () => {
    console.log("Editing Location");
    let data = {
      PointID: wellData.PointID,
      MonitoringStatusCompositeCode: monitoringStatus
        .map((s) => s.code)
        .join(""),
      // MonitoringStatusCode: monitoringStatus.code,
      // MonitoringStatus: monitoringStatus.name,
    };
    let url = "authorized/wells/edit";
    nmbgmr_postJson(url, data, tokenInfo?.access_token)
      .then((response) => {
        console.log("resp", response);
        if (response.status === "success") {
          console.log("Success");
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `Well ${wellData.PointID} updated`,
            life: 3000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Error updating well ${wellData.PointID}`,
          life: 3000,
        });
      });
  };

  useEffect(() => {
    if (wellData === undefined) return;

    if (
      wellData.monitoring_status === undefined ||
      wellData.monitoring_status === "" ||
      wellData.monitoring_status === null
    ) {
      setMonitoringStatus([]);
      return;
    }

    let ms = wellData.monitoring_status.split(",");
    let mss = [];
    for (let i = 0; i < ms.length; i++) {
      let mi = ms[i];
      let msi = availableMonitoringStatus.find((s) => s.name === mi);
      if (msi !== undefined) {
        mss.push(msi);
      }
    }
    setMonitoringStatus(mss);
    console.log("locae", wellData);
  }, [wellData]);

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <h3>Monitoring Status</h3>
        <MultiSelect
          value={monitoringStatus}
          options={availableMonitoringStatus}
          onChange={(e) => setMonitoringStatus(e.value)}
          optionLabel="name"
          placeholder="Select a Monitoring Status"
          display="chip"
        />
        <Button
          className={"ml-2"}
          onClick={handleEditLocation}
          label={"Edit"}
        />
      </div>
    </div>
  );
}
// ============= EOF =============================================
