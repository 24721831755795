// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";

export const makeHydrographExportHeaderTemplate = (
  onDownloadWaterLevels,
  config,
  setConfig,
) => {
  const Template = (options) => {
    const infoTextOverlayPanelRef = useRef(null);
    const onDailyChange = (e) => {
      setConfig({ ...config, daily: e.checked });
    };

    const onSettings = (e) => {
      infoTextOverlayPanelRef.current.toggle(e);
    };
    return (
      <div className={options.className}>
        <OverlayPanel
          ref={infoTextOverlayPanelRef}
          // showCloseIcon
        >
          <div>
            {" "}
            <Checkbox
              tooltip={
                "Download Daily Water Levels.  If unchecked, download all water levels. " +
                "Daily Water Levels means download only the max depth to water for each day."
              }
              onChange={onDailyChange}
              checked={config.daily}
            ></Checkbox>
            <label className="ml-2">Download Daily Water Levels</label>
          </div>
        </OverlayPanel>

        <div>{options.titleElement}</div>
        <div>
          {/*<div>*/}

          <Button
            icon={"pi pi-cog"}
            tooltip={"Download Configuration"}
            tooltipOptions={{ showDelay: 500, position: "top" }}
            className={"p-button-outlined"}
            onClick={onSettings}
          />
          <Button
            icon={"pi pi-download"}
            tooltip={"Export as CSV"}
            tooltipOptions={{ showDelay: 500, position: "top" }}
            className={"p-button-outlined"}
            onClick={onDownloadWaterLevels}
          />
          {options.togglerElement}
        </div>
      </div>
    );
  };
  return Template;
};

export const makeExportHeaderTemplate = (downloader) => {
  const template = (options) => {
    return (
      <div className={options.className}>
        <div>{options.titleElement}</div>
        <div>
          <Button
            icon={"pi pi-download"}
            tooltip={"Export as CSV"}
            tooltipOptions={{ showDelay: 500, position: "top" }}
            className={"p-button-outlined"}
            onClick={downloader}
          />
          {options.togglerElement}
        </div>
      </div>
    );
  };
  return template;
};

export const makePanelHeaderTemplate = (onInfo) => {
  return (options) => {
    return (
      <div className={options.className}>
        <div>{options.titleElement}</div>
        <div>
          <Button
            icon={"pi pi-info-circle"}
            tooltip={"Info"}
            // tooltipOptions={{ showDelay: 500 }}
            onClick={onInfo}
            // onClick={(e) => {
            //     setInfoText(infoText);
            //     infoTextOverlayPanelRef.current.toggle(e);
            // }}
            className={"p-button-outlined"}
          />
          {options.togglerElement}
        </div>
      </div>
    );
  };
};
// ============= EOF =============================================
