// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { useEffect, useState } from "react";
import { nmbgmr_getJson } from "../../util";
import { useFiefTokenInfo } from "@fief/fief/react";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Column } from "primereact/column";

export default function ManualMeasurementsTable({ pointId }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const tokenInfo = useFiefTokenInfo();
  const auth_api_getJson = (url) => {
    return nmbgmr_getJson(url, tokenInfo?.access_token);
  };

  useEffect(() => {
    if (pointId === undefined) {
      return;
    }

    setLoading(true);
    auth_api_getJson(`waterlevels/manual?pointid=${pointId}`).then((data) => {
      console.log("asdf", data);
      data = data.sort((a, b) => {
        return new Date(b.DateMeasured) - new Date(a.DateMeasured);
      });
      setRows(data);
      setLoading(false);
    });
  }, [pointId]);

  return (
    <div>
      <DataTable
        size={"small"}
        className={"compact-table"}
        stripedRows
        value={rows}
        loading={loading}
        loadingIcon={<ProgressSpinner />}
        paginator
        rows={10}
      >
        <Column field="DateMeasured" header="Date Measured" sortable />
        <Column field="TimeMeasured" header="Time Measured" sortable />
        <Column field="DepthToWaterBGS" header="Depth to Water" sortable />
        <Column field="LevelStatus" header="Level Status" sortable />
        <Column field={"MeasurementMethod"} header={"Measurement Method"} />
        <Column field="DataQuality" header="DataQuality" sortable />
        <Column field={"Data Source"} header={"Data Source"} sortable />
        <Column
          field={"MeasuringAgency"}
          header={"Measuring Agency"}
          sortable
        />
      </DataTable>
    </div>
  );
}
// ============= EOF =============================================
