// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { useEffect, useRef, useState } from "react";
import { useFiefTokenInfo } from "@fief/fief/react";
import { nmbgmr_getJson } from "../../util";
import AMPAPIHydrograph from "../LocationDetail/AMPAPIHydrograph";
import MapComponent from "../Map/MapComponent";

export default function ContinuousDashboard() {
  const [wells, setWells] = useState();
  const [mapData, setMapData] = useState();
  const [selectedPointID, setSelectedPointID] = useState();
  // const [loading, setLoading] = useState;
  const tokenInfo = useFiefTokenInfo();
  const mapRef = useRef();
  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/mapbox/satellite-streets-v11",
  );

  const auth_api_getJson = (url) => {
    return nmbgmr_getJson(url, tokenInfo?.access_token);
  };
  useEffect(() => {
    console.log("ContinuousDashboard");
    let url = "locations/continuous?continuous=All";
    auth_api_getJson(url).then((data) => {
      console.log("fff", data);
      setWells(data.features);
      setMapData(data);
      // setLoading(false);
    });
  }, []);

  return (
    <div>
      <Panel>
        <div className={"grid"}>
          <div className={"col-6"}>
            <DataTable
              value={wells}
              stripedRows
              paginator
              rows={25}
              rowsPerPageOptions={[25, 50, 100]}
              selectionMode="single"
              selection={selectedPointID}
              onSelectionChange={(e) => {
                console.log(e.value["properties"]["name"]);
                setSelectedPointID(e.value["properties"]["name"]);
              }}
              className={"compact-table"}
              size={"small"}
            >
              <Column header={"PointID"} field={"properties.name"} />
              <Column
                header={"Alternate Site ID"}
                field={"properties.alternate_site_id"}
              />
              <Column header={"Site Names"} field={"properties.site_names"} />
              <Column header={"OSE Well ID"} field={"properties.ose_well_id"} />
              <Column header={"formation"} field={"properties.formation"} />
              <Column
                header={"Method"}
                field={"properties.measurement_method"}
              />
              {/*<Column*/}
              {/*  header={"Total Manual Waterlevels"}*/}
              {/*  field={"properties.total_manual_waterlevels"}*/}
              {/*/>*/}
              <Column
                sortable
                header={"Total Continuous Waterlevels"}
                field={"properties.total_continuous_waterlevels"}
              />
              <Column
                sortable
                header={"Last Measurement Date"}
                field={"properties.last_measurement_date"}
              />
              <Column
                sortable
                header={"Well Depth (ft)"}
                field={"properties.well_depth.value"}
              />
            </DataTable>
          </div>
          <div className={"col-6"}>
            <MapComponent
              mapStyle={mapStyle}
              // selectionPolygons={selectionPolygons}
              // setSelectionPolygons={setSelectionPolygons}
              sourceData={{ results: mapData }}
              sources={[
                {
                  id: "results",
                  label: "Results",
                  url: "foob",
                  paint: {
                    "circle-radius": 4,
                    "circle-stroke-width": 1,
                    "circle-stroke-color": "#000000",
                    "circle-color": [
                      "match",
                      ["get", "measurement_method"],
                      "Transducer",
                      "#d5633a",
                      "Continuous acoustic sounder",
                      "#3a67d5",
                      "#79878f",
                    ],
                  },
                },
              ]}
              // layerVisibility={layerVisibility}
              // showDrawControls={{ show: true, position: "top-right" }}
              mapRef={mapRef}
            />
          </div>
        </div>
      </Panel>
      <Panel>
        <AMPAPIHydrograph
          showTable={true}
          showDisclaimer={false}
          useContinousData={true}
          pointId={selectedPointID}
        />
      </Panel>
    </div>
  );
}
// ============= EOF =============================================
