// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Tree } from "primereact/tree";
import { useEffect, useState } from "react";
import DocAndHelp from "./Pages/DocAndHelp";
import Disclaimer from "./Pages/Disclaimer";
import WorkInProgress from "./Pages/WorkInProgress";
import SiteServiceFAQ from "./Pages/SiteService/FAQ";
import SiteServiceDetails from "./Pages/SiteService/Details";
import GroundwaterServiceFAQ from "./Pages/GroundwaterService/FAQ";
import GroundwaterServiceDetails from "./Pages/GroundwaterService/Details";
import { BreadCrumb } from "primereact/breadcrumb";

const DocumentationTree = ({ selectedKey, setSelectedKey }) => {
  const tree = [
    { label: "Documentation and Help", key: "documentation" },
    { label: "Disclaimer", key: "disclaimer" },
    {
      label: "Site Service",
      key: "siteservice",
      children: [
        { label: "FAQ", key: "siteservice_faq" },
        { label: "Service Details", key: "siteservice_details" },
      ],
    },
    {
      label: "Groundwater Levels Service",
      key: "gwlservice",
      children: [
        { label: "FAQ", key: "gwlservice_faq" },
        { label: "Service Details", key: "gwlservice_details" },
      ],
    },
  ];

  return (
    <Tree
      className={"surface-200"}
      selectionMode="single"
      selectionKeys={selectedKey}
      onSelectionChange={(e) => setSelectedKey(e.value)}
      value={tree}
    ></Tree>
  );
};

const DocumentationContent = ({ content, breadcrumb }) => {
  return (
    <div>
      <BreadCrumb model={breadcrumb} />
      {content}
    </div>
  );
};

export default function MainDocumentationPage() {
  const [content, setContent] = useState();
  const [selectedKey, setSelectedKey] = useState("documentation");
  const defaultBreadCrumb = [
    {
      label: "Documentation and Help",
      command: () => {
        setSelectedKey("documentation");
      },
    },
  ];
  const [breadcrumb, setBreadcrumb] = useState(defaultBreadCrumb);

  const makeBreadCrumb = (label, key) => {
    return {
      label: label,
      command: () => {
        setSelectedKey(key);
      },
    };
  };
  useEffect(() => {
    switch (selectedKey) {
      case "documentation":
        setContent(<DocAndHelp />);
        setBreadcrumb(defaultBreadCrumb);
        break;
      case "disclaimer":
        setContent(<Disclaimer />);
        setBreadcrumb([
          ...defaultBreadCrumb,
          makeBreadCrumb("Disclaimer", "disclaimer"),
        ]);
        break;
      case "siteservice_faq":
        setBreadcrumb([
          ...defaultBreadCrumb,
          makeBreadCrumb("Site Service", "siteservice"),
          makeBreadCrumb("FAQ", "siteservice_faq"),
          // { label: "Site Service" },
          // { label: "FAQ" },
        ]);
        setContent(<SiteServiceFAQ />);
        break;
      case "siteservice_details":
        setBreadcrumb([
          ...defaultBreadCrumb,
          makeBreadCrumb("Site Service", "siteservice"),
          makeBreadCrumb("Details", "siteservice_details"),
        ]);
        setContent(<SiteServiceDetails />);
        break;
      case "gwlservice_faq":
        setBreadcrumb([
          ...defaultBreadCrumb,
          makeBreadCrumb("Groundware Service", "gwlservice"),
          makeBreadCrumb("FAQ", "gwlservice_faq"),
        ]);
        setContent(<GroundwaterServiceFAQ />);
        break;
      case "gwlservice_details":
        setBreadcrumb([
          ...defaultBreadCrumb,
          makeBreadCrumb("Groundwater Service", "gwlservice"),
          makeBreadCrumb("Details", "siteservice_details"),
        ]);
        setContent(<GroundwaterServiceDetails />);
        break;
      default:
        setContent(<WorkInProgress />);
        break;
    }
    if (selectedKey === "documentation") {
      setContent(<DocAndHelp />);
    }
  }, [selectedKey]);

  return (
    <div className={"grid"}>
      <div className={"col-3"}>
        <DocumentationTree
          selectedKey={selectedKey}
          setSelectedKey={setSelectedKey}
        />
      </div>
      <div className={"col-9"}>
        <DocumentationContent breadcrumb={breadcrumb} content={content} />
      </div>
    </div>
  );
}

// ============= EOF =============================================
