// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import pws_nm from "../data/pws_nm.json";
import pws_nm2024 from "../data/pws_nm_2024.json";
import MapComponent from "./Map/MapComponent";
import { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { boldBodyTemplateGen } from "./LocationDetail/KeyValueTable";
import { Button } from "primereact/button";

export default function PWS() {
  const [sourceData, setSourceData] = useState({});
  const [popupContent, setPopupContent] = useState(undefined);
  const [stickyPopup, setStickyPopup] = useState(false);
  const mapRef = useRef();

  const downloadPWS = () => {
    const element = document.createElement("a");

    pws_nm.features = pws_nm.features.filter(
      (x) => x.geometry.type === "Point",
    );
    let combined = pws_nm.features.concat(pws_nm2024.features);

    const file = new Blob([JSON.stringify(combined)], {
      type: "application/json",
    });
    element.href = URL.createObjectURL(file);
    element.download = "pws_nm.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const showPointPopup = (points) => {
    if (points.length === 0) {
      setPopupContent(undefined);
      return;
    }

    let selected_point = points[0];

    let rows = [
      { key: "OBJECTID", value: selected_point.properties.OBJECTID },
      {
        key: "WaterSystem ID",
        value: selected_point.properties.WaterSystem_ID,
      },
      {
        key: "WaterSystem Name",
        value: selected_point.properties.Public_Water_System_Name_2019,
      },
    ];

    setPopupContent({
      coordinates: selected_point.geometry.coordinates,
      children: (
        <DataTable className={"compact-table"} size={"small"} value={rows}>
          <Column
            field={"key"}
            header={"Attribute"}
            body={boldBodyTemplateGen("key")}
          />
          <Column field={"value"} header={"Value"} />
        </DataTable>
      ),
    });
  };

  const showBoundaryPopup = (e, boundaries) => {
    if (boundaries.length === 0) {
      setPopupContent(undefined);
      return;
    }

    let selected = boundaries[0];
    let rows = [
      { key: "Water_System_ID", value: selected.properties.Water_System_ID },
      { key: "PublicSystemName", value: selected.properties.PublicSystemName },
      { key: "City", value: selected.properties.CITY },
      { key: "Sys_Type", value: selected.properties.Sys_Type },
    ];
    setPopupContent({
      coordinates: [e.lngLat.lng, e.lngLat.lat],
      children: (
        <DataTable className={"compact-table"} size={"small"} value={rows}>
          <Column
            field={"key"}
            header={"Attribute"}
            body={boldBodyTemplateGen("key")}
          />
          <Column field={"value"} header={"Value"} />
        </DataTable>
      ),
    });
  };
  const onMouseMove = (e, sp, setPopupContent) => {
    sp = sp.filter((x) => x !== undefined);

    let points = sp.filter(
      (x) =>
        x.geometry.type !== "MultiPolygon" && x.geometry.type !== "Polygon",
    );
    if (points.length > 0) {
      showPointPopup(points);
      return;
    }

    let boundaries = sp.filter(
      (x) =>
        x.geometry.type === "MultiPolygon" || x.geometry.type === "Polygon",
    );

    if (boundaries.length > 0) {
      showBoundaryPopup(e, boundaries);
      return;
    }
  };

  let sources = [
    {
      id: "weaver:pws:boundaries2024",
      url: "mapbox://jakerosstech.7y6z3g0o",
      layer_type: "fill",
      paint: {
        "fill-color": "#2247d0",
        "fill-outline-color": "#000000",
        "fill-opacity": 0.75,
      },
      dataFetcher: async () => {
        console.log("asdfasdfasdfsaf");
        let boundaries = pws_nm2024.features.filter(
          (x) => x.geometry.type === "Polygon",
        );
        let source = {
          type: "FeatureCollection",
          name: "pws_nm",
          crs: {
            type: "name",
            properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
          },
          features: boundaries,
        };
        console.log("sourceffff", source);
        return source;
      },
    },
    // {
    //   id: "weaver:pws:boundaries",
    //   url: "mapbox://jakerosstech.7y6z3g0o",
    //   layer_type: "fill",
    //   paint: {
    //     "fill-color": "#6ec9c9",
    //     "fill-outline-color": "#000000",
    //     "fill-opacity": 0.5,
    //   },
    //   dataFetcher: async () => {
    //     let boundaries = pws_nm.features.filter(
    //       (x) => x.geometry.type === "MultiPolygon",
    //     );
    //     let source = {
    //       type: "FeatureCollection",
    //       name: "pws_nm",
    //       crs: {
    //         type: "name",
    //         properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    //       },
    //       features: boundaries,
    //     };
    //     return source;
    //   },
    // },
    {
      id: "weaver:pws:wells",
      url: "mapbox://jakerosstech.7y6z3g0o",
      dataFetcher: async () => {
        console.log("asd", pws_nm);
        let points = pws_nm.features.filter((x) => x.geometry.type === "Point");
        let source = {
          type: "FeatureCollection",
          name: "pws_nm",
          crs: {
            type: "name",
            properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
          },
          features: points,
        };
        return source;
      },
    },
  ];

  return (
    <div>
      <h1>Public Water Systems</h1>
      <p>
        The Public Water Systems (PWS) component will be used to display
        information about public water supplies in New Mexico.
      </p>
      <Button onClick={downloadPWS}>Download PWS</Button>
      <MapComponent
        mapRef={mapRef}
        mapStyle={"mapbox://styles/mapbox/satellite-streets-v11"}
        onMouseMoveCallback={onMouseMove}
        popupContent={popupContent}
        setPopupContent={setPopupContent}
        stickyPopup={stickyPopup}
        setStickyPopup={setStickyPopup}
        sourceData={sourceData}
        setSourceData={setSourceData}
        property_identifier={"OBJECTID"}
        sources={sources}
      />
    </div>
  );
}
// ============= EOF =============================================
