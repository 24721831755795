// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { Slider } from "primereact/slider";
import { Label } from "theme-ui";
import { Tree } from "primereact/tree";

export default function StyleControl({ mapRef, style, setStyle }) {
  const [layers, setLayers] = useState([]);

  const [color, setColor] = useState({});
  const [size, setSize] = useState({});
  const [opacity, setOpacity] = useState({});
  const [strokeColor, setStrokeColor] = useState({});
  const [strokeWidth, setStrokeWidth] = useState({});

  const makeColorPicker = (node, color, setColor) => {
    return (
      <div className={"grid border-2 border-round"}>
        <div className={"col-3"}>
          <Label className={"p-0"}>{node.label}</Label>
        </div>
        <div className={"col-6"}>
          <ColorPicker
            value={color[node.layer]}
            onChange={(e) =>
              setColor({ ...color, [node.layer]: `#${e.value}` })
            }
          />
        </div>
      </div>
    );
  };

  const makeSlider = (node, value, setValue) => {
    return (
      <div className={"grid border-2 border-round"}>
        <div className={"col-3"}>
          <Label className={"p-0"}>{node.label}</Label>
        </div>
        <div className={"col-6"}>
          <div className={"w-11rem"}>
            <Slider
              value={value[node.layer]}
              onChange={(e) => setValue({ ...size, [node.layer]: e.value })}
            />
          </div>
        </div>
      </div>
    );
  };
  const nodeTemplate = (node, options) => {
    if (node.key === "color") {
      return makeColorPicker(node, color, setColor);
    } else if (node.key === "size") {
      return makeSlider(node, size, setSize);
    } else if (node.key === "opacity") {
      return makeSlider(node, opacity, setOpacity);
    } else if (node.key === "strokeColor") {
      return makeColorPicker(node, strokeColor, setStrokeColor);
    } else if (node.key === "strokeWidth") {
      return makeSlider(node, strokeWidth, setStrokeWidth);
    }
    return <div>{node.label}</div>;
  };

  useEffect(() => {
    let currentStyle = mapRef.current.getStyle();
    let localLayers = [];
    for (let layer of currentStyle.layers) {
      if (layer.id.startsWith("weaver")) {
        setColor((prev) => ({
          ...prev,
          [layer.id]: layer.paint["circle-color"],
        }));
        setSize((prev) => ({
          ...prev,
          [layer.id]: layer.paint["circle-radius"] * 10,
        }));
        setOpacity((prev) => ({
          ...prev,
          [layer.id]: layer.paint["circle-opacity"]
            ? layer.paint["circle-opacity"] * 100
            : 100,
        }));
        setStrokeColor((prev) => ({
          ...prev,
          [layer.id]: layer.paint["circle-stroke-color"],
        }));
        setStrokeWidth((prev) => ({
          ...prev,
          [layer.id]: layer.paint["circle-stroke-width"] * 10,
        }));

        localLayers.push({
          key: layer.id,
          label: layer.id.replace("weaver:", ""),
          children: [
            { key: "color", label: "Color", layer: layer.id },
            { key: "size", label: "Size", layer: layer.id },
            { key: "opacity", label: "Opacity", layer: layer.id },
            { key: "strokeColor", label: "Stroke Color", layer: layer.id },
            { key: "strokeWidth", label: "Stroke Width", layer: layer.id },
          ],
        });
      }
    }

    setLayers(localLayers);
  }, [mapRef]);

  useEffect(() => {
    if (color === undefined || size === undefined) {
      return;
    }
    let currentStyle = mapRef.current.getStyle();
    for (let layer of currentStyle.layers) {
      if (layer.id in color) {
        layer.paint["circle-color"] = `${color[layer.id]}`;
        layer.paint["circle-radius"] = size[layer.id] / 10;
        layer.paint["circle-opacity"] = opacity[layer.id] / 100;
        layer.paint["circle-stroke-color"] = strokeColor[layer.id];
        layer.paint["circle-stroke-width"] = strokeWidth[layer.id] / 10;
      }
    }
    setStyle(currentStyle);
  }, [color, size, opacity, strokeWidth, strokeColor]);

  return (
    <div>
      {/*<div>*/}
      {/*    <Label className={'p-3'}>Color</Label>*/}
      {/*    <ColorPicker value={color} onChange={(e) => setColor(`#${e.value}`)} />*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*    <Label className={'p-3'}>Size</Label>*/}
      {/*    <Slider value={size} onChange={(e) => setSize(e.value)} />*/}
      {/*</div>*/}
      <div>
        <Tree value={layers} nodeTemplate={nodeTemplate} />
      </div>
    </div>
  );
}
// ============= EOF =============================================
