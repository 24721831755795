// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

const PointsListDialog = ({ visible, onHide, points }) => {
  const handleRowClick = (rowData) => {
    window.open("/location/" + rowData.properties.name, "_blank");
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <Button
        label={rowData.properties.name}
        className="p-button-link"
        onClick={() => handleRowClick(rowData)}
      />
    );
  };

  return (
    <Dialog visible={visible} onHide={onHide} header="Multiple sites at location - select one:">
      <DataTable value={points} size="small" stripedRows>
        <Column field="properties.name" header="Name" body={nameBodyTemplate}></Column>
        <Column field="properties.elevation_ft" header="Elevation (ft)"></Column>
        <Column field="properties.well_depth.value" header="Well Depth (ft)"></Column>
        <Column field="properties.hole_depth.value" header="Hole Depth (ft)"></Column>
        <Column field="properties.ose_well_id" header="OSE Well ID"></Column>
        <Column field="properties.site_id" header="Site ID"></Column>
        <Column field="properties.alternate_site_id" header="Alternate Site ID"></Column>
      </DataTable>
    </Dialog>
  );
};

export default PointsListDialog;
// ============= EOF =============================================