export let settings = {
  mapbox: {
    token:
      "pk.eyJ1IjoiamFrZXJvc3N3ZGkiLCJhIjoiY2s3M3ZneGl4MGhkMDNrcjlocmNuNWg4bCJ9.4r1DRDQ_ja0fV2nnmlVT0A",
  },
  fief: {
    url: "https://fief.newmexicowaterdata.org",
    client_id: "buShmB5KqjE5kirVSz9J2g6of5O276OhHBzUcZLpGEA",
  },
  nmbgmr_api_url: "https://waterdata.nmt.edu/",
  // nmbgmr_api_url: "http://localhost:8009/",
  apim_url: "https://apigee.newmexicowaterdata.org/",
  mode: process.env.NODE_ENV,

  die_worker_url:
    "https://die-worker-dot-waterdatainitiative-271000.appspot.com/",
  // die_worker_url: "http://127.0.0.1:5001/",
  die_url: "https://die-dot-waterdatainitiative-271000.appspot.com/",
};

if (process.env.NODE_ENV === "production") {
  settings = {
    ...settings,
    nmbgmr_api_url: "https://waterdata.nmt.edu/",
    apim_url: "https://apigee.newmexicowaterdata.org/",
    die_url: "https://die-dot-waterdatainitiative-271000.appspot.com/",
    die_worker_url:
      "https://die-worker-dot-waterdatainitiative-271000.appspot.com/",
  };
}

if (process.env.NODE_USE_LOCAL_API === "true") {
  settings = {
    ...settings,
    nmbgmr_api_url: "http://localhost:8000/",
    apim_url: "http://localhost:8002/",
  };
}
