// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Tree } from "primereact/tree";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";

export default function LayerControl({
  layers,
  layerVisibility,
  handleLayerSelection,
  handleLayerDownload,
}) {
  const [nodes, setNodes] = useState(null);
  const [selected, setSelected] = useState(null);

  const nodeMaker = (node) => {
    const isNodeSelected = selected?.[node.key]?.checked ?? false;

    return (
      <div
        className={`flex w-10 align-items-center justify-content-between p-1 border-round`}
        // style={{ backgroundColor: node.style.background }}
      >
        <div className="pr-1">
        <label
          className={`p-1 border-round-sm ${isNodeSelected ? 'bg-black text-white' : 'bg-white text-black'} custom-node-label`}
        >
            {node.label}
          </label>
        </div>
        <div>
          <Button
            tooltip={"Download Layer as a GeoJSON file"}
            icon="pi pi-download"
            onClick={(e) => {
              console.log("download");
              handleLayerDownload(node.key);
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const tonode = (layer) => {
      let color = layer.paint["circle-color"];
      if (typeof color !== "string") {
        color = color[3];
      }

      return {
        key: layer.id,
        label: layer.label,
        // color: color,
        style: { background: color },
        children: layer.children?.map((child) => tonode(child)),
      };
    };

    const nodes = layers.map(tonode);
    setNodes(nodes);

    let sel = {};
    layers.forEach((layer) => {
      sel[layer.id] = {
        checked: layerVisibility[layer.id] === "visible",
        partialChecked: false,
      };
    });
    console.log(sel);
    setSelected(sel);
  }, []);

  return (
    // <div className={"grid"}>
    <Tree
      value={nodes}
      selectionMode={"checkbox"}
      selectionKeys={selected}
      nodeTemplate={nodeMaker}
      onSelectionChange={(e) => {
        // prevent selection if download button clicked
        let className = e.originalEvent.target.className;
        if (
          typeof className === "string" &&
          className.includes("pi-download")
        ) {
          return;
        }

        setSelected(e.value);
        handleLayerSelection(e.value);
      }}
    />
    // </div>
  );
}
// ============= EOF =============================================
