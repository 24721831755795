// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import Papa from "papaparse";
import { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { downloadCSV } from "../download_util";

export default function WellUpload() {
  const [previewData, setPreviewData] = useState([]);
  const fileUploadRef = useRef();

  const handleDownloadTemplate = () => {
    let rows = [
      [
        "PointID",
        "OwnerFirstName",
        "OwnerLastName",
        "Easting",
        "Northing",
        "MeasuringPoint",
        "MeasuringPointHeight",
      ],
    ];
    downloadCSV("well_upload_template", rows);
  };

  const onSelect = (event) => {
    console.log("onUpload", event);
    var fileReader = new FileReader();
    fileReader.onload = function () {
      // fileOutput.textContent=fileReader.result;

      let txt = fileReader.result;
      console.log(txt);
      // CSVToArray(txt)
      let pd = Papa.parse(txt, { header: true });
      console.log(pd);
      // let pd = [{'WellId': '1'}, {'WellId': '2'}, {'WellId': '3'}]
      setPreviewData(pd.data);
    };
    fileReader.readAsText(event.files[0]);
  };
  const onClear = () => {
    setPreviewData([]);
  };

  return (
    <div>
      <h1>Well Upload</h1>
      <Button onClick={handleDownloadTemplate}>Download Example</Button>
      <FileUpload
        ref={fileUploadRef}
        name="file"
        // url={`${settings.nmbgmr_api_url}authorized/waterchemistry/limsupload`}
        // multiple
        onSelect={onSelect}
        // chooseOptions={chooseOptions}
        // uploadOptions={uploadOptions}
        // cancelOptions={cancelOptions}
        // onError={onError}
        onRemove={onClear}
        onClear={onClear}
        // onBeforeSend={onBeforeSend}
        // onUpload={onUpload}
        accept="application/text"
        // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        maxFileSize={10000000}
        // headerTemplate={headerTemplate}
        // itemTemplate={itemTemplate}
        emptyTemplate={
          <p className="m-0">Drag and drop Well CSV file here to upload.</p>
        }
      />
      <DataTable value={previewData}>
        <Column field={"PointID"} header={"Point ID"} />
        <Column field={"OwnerFirstName"} header={"Owner First Name"} />
        <Column field={"OwnerLastName"} header={"Owner Last Name"} />
        <Column field={"Easting"} header={"Easting"} />
        <Column field={"Northing"} header={"Northing"} />
        <Column field={"MeasuringPoint"} header={"Measuring Point"} />
        <Column
          field={"MeasuringPointHeight"}
          header={"Measuring Point Height"}
        />
      </DataTable>
    </div>
  );
}
// ============= EOF =============================================
