// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { useEffect, useState } from "react";
import { nmbgmr_getJson } from "../../util";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";

const ANALYTES = [
  "HRD",
  "TDS",
  "ALK",
  "Ca",
  "Ca(total)",
  "Mg",
  "Mg(total)",
  "Na",
  "Na(total)",
  "K",
  "K(total)",
  "CO3",
  "SO4",
  "Cl",
  "HCO3",
  "pHL",
];
const majorTemplate = (rowData, column) => {
  return rowData[column.field]?.toFixed(3);
};

export const MAJOR_CHEM_FIELDS = [
  { key: "AnalysisDate", header: "AnalysisDate", body: null },
  { key: "HRD", header: "HRD", body: null },
  { key: "TDS", header: "TDS", body: null },
  { key: "ALK", header: "ALK", body: null },
  { key: "pHL", header: "pH", body: majorTemplate },
  { key: "Ca", header: "Ca", body: majorTemplate },
  { key: "Ca(total)", header: "Ca(Total)", body: majorTemplate },
  { key: "CaUnits", header: "", body: null },
  { key: "Mg", header: "Mg", body: majorTemplate },
  { key: "Mg(total)", header: "Mg(Total)", body: majorTemplate },
  { key: "MgUnits", header: "", body: null },
  { key: "Na", header: "Na", body: majorTemplate },
  { key: "Na(total)", header: "Na(Total)", body: majorTemplate },
  { key: "NaUnits", header: "", body: null },
  { key: "K", header: "K", body: majorTemplate },
  { key: "K(total)", header: "K(Total)", body: majorTemplate },
  { key: "KUnits", header: "", body: null },
  { key: "CO3", header: "CO3", body: majorTemplate },
  { key: "CO3Units", header: "", body: null },
  { key: "HCO3", header: "HCO3", body: majorTemplate },
  { key: "HCO3Units", header: "", body: null },
  { key: "SO4", header: "SO4", body: majorTemplate },
  { key: "SO4Units", header: "", body: null },
  { key: "Cl", header: "Cl", body: majorTemplate },
  { key: "ClUnits", header: "", body: null },
];

export async function assembleMajorChemistry(pointID, callback) {
  return nmbgmr_getJson(`waterchemistry?pointid=${pointID}`).then((data) => {
    console.log("water chem data", data);
    let groups = Object.groupBy(data, (e, idx) => {
      return e["SamplePointID"].slice(-1);
    });

    let rows = Object.keys(groups).map((key) => {
      let row = {
        AnalysisDate: new Date(groups[key][0]["AnalysisDate"])
          .toISOString()
          .split("T")[0],
      };

      for (const analyte of ANALYTES) {
        let a = groups[key].find((e) => e["Analyte"] === analyte);
        row[analyte] = a?.SampleValue;
        row[analyte + "Units"] = a?.Units;
      }
      return row;
    });

    rows = rows.sort((a, b) => {
      return new Date(a["AnalysisDate"]) - new Date(b["AnalysisDate"]);
    });

    rows = rows.reverse();
    if (callback !== undefined) {
      callback(rows);
    }
    return rows;
    // setMajorChemistryData(rows.reverse());
    // setMajorChemistry(rows.reverse());
  });
}

export default function WaterChem({ pointID, setMajorChemistryData }) {
  const [majorChemistry, setMajorChemistry] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("pointID", pointID);
    // setLoading(true);
    // assembleMajorChemistry(pointID, (data) => {
    //   setMajorChemistry(data);
    //   setMajorChemistryData(data);
    //   setLoading(false);
    // }).finally(() => {
    //   setLoading(false);
    // });
  }, []);

  return (
    <div className={"grid flex justify-content-center"}>
      {loading && (
        <ProgressSpinner
          strokeWidth={3}
          className={"absolute justify-content-center"}
        />
      )}
      <div className={"col-12 bg-red-100 text-center"}>
        <h2>!! Waterchemistry under construction !!</h2>
      </div>
      <div className={"col-12"}>
        <DataTable
          columnResizeMode={"expand"}
          resizableColumns
          showGridlines
          tableStyle={{ minWidth: "50rem" }}
          paginator
          rows={10}
          stripedRows
          className={"compact-table"}
          size={"small"}
          value={majorChemistry}
          rowHover
        >
          {MAJOR_CHEM_FIELDS.map((field) => {
            return (
              <Column
                key={field.key}
                field={field.key}
                header={field.header}
                body={field.body}
              />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
}
// ============= EOF =============================================
