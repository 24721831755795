// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import React, { useEffect, useState } from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";

const Plot = createPlotlyComponent(Plotly);

export default function HydrographViewer() {
  const [data, setData] = useState([]);
  const [layout] = useState({
    height: 600,
    width: 850,
    // width: showTable ? 800 : 1200,
    // autosize: true,
    xaxis: { title: "Date" },
    yaxis: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  });

  useEffect(() => {
    let iotid = 9648;
    let url =
      `https://st2.newmexicowaterdata.org/FROST-Server/v1.1/` +
      `Locations(${iotid})?$expand=Things/Datastreams`;
    fetch(url).then((response) => {
      response.json().then((data) => {
        console.log(data);
        let ds = data.Things[0].Datastreams[0];
        let obsUrl =
          "https://st2.newmexicowaterdata.org/FROST-Server/v1.1/" +
          `Datastreams(${ds["@iot.id"]})/Observations?$orderby=phenomenonTime desc`;
        fetch(obsUrl).then((response) => {
          response.json().then((data) => {
            console.log(data);
            let series = data.value.map((o) => {
              return { x: o.phenomenonTime, y: o.result };
            });
            setData([{ x: series.map((s) => s.x), y: series.map((s) => s.y) }]);
          });
        });

        // let series = obs.map((o) => {
        //   return { x: o.phenomenonTime, y: o.result };
        // });
        // setData([{ x: series.map((s) => s.x), y: series.map((s) => s.y) }]);
      });
    });
  }, []);

  return (
    <div>
      <h1>Hydrograph Viewer</h1>

      <Plot
        divId={"hydrograph"}
        data={data}
        layout={layout}
        // config={{ responsive: true }}
      />
    </div>
  );
}
// ============= EOF =============================================
