// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Dropdown } from "primereact/dropdown";
import streets from "../../img/basemap/Streets.png";
import outdoors from "../../img/basemap/Outdoors.png";
import dark from "../../img/basemap/Dark.png";
import light from "../../img/basemap/Light.png";
import satellite from "../../img/basemap/Satellite.png";
import satellite_streets from "../../img/basemap/SatelliteStreets.png";
import navigation_day from "../../img/basemap/NavigationDay.png";
import navigation_night from "../../img/basemap/NavigationNight.png";
import { useState } from "react";

const styles = [
  {
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
    image: streets,
  },
  { name: "Dark", url: "mapbox://styles/mapbox/dark-v10", image: dark },
  { name: "Light", url: "mapbox://styles/mapbox/light-v10", image: light },
  {
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-v9",
    image: satellite,
  },
  {
    name: "Satellite Streets",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
    image: satellite_streets,
  },
  {
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
    image: outdoors,
    source_url: "mapbox://mapbox.outdoors-v11",
  },
  {
    name: "Navigation Day",
    url: "mapbox://styles/mapbox/navigation-day-v1",
    image: navigation_day,
  },
  {
    name: "Navigation Night",
    url: "mapbox://styles/mapbox/navigation-night-v1",
    image: navigation_night,
  },
];

const basemapTemplate = (option, props) => {
  if (option) {
    return (
      <div className="basemap-item basemap-item-value">
        <img alt={option.label} src={option?.image} />
        <div>{option.label}</div>
      </div>
    );
  }

  return <span>{props.placeholder}</span>;
};

const basemapOptionTemplate = (option) => {
  return (
    <div className="country-item">
      <img
        alt={option.label}
        src={option?.image}
        // className={`flag flag-${option.code.toLowerCase()}`}
      />
      <div>{option.label}</div>
    </div>
  );
};
export default function BaseMapControl({ setMapStyle }) {
  const [baseMap, setBaseMap] = useState("Satellite Streets");

  const onChange = (e) => {
    setBaseMap(e.value);
    for (const style of styles) {
      if (style.name === e.value) {
        setMapStyle(style.url);
        break;
      }
    }
  };

  return (
    <div>
      <Dropdown
        value={baseMap}
        valueTemplate={basemapTemplate}
        itemTemplate={basemapOptionTemplate}
        options={styles.map((item) => {
          return { label: item.name, value: item.name, image: item.image };
        })}
        onChange={onChange}
      />
    </div>
  );
}
// ============= EOF =============================================
