// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Carousel } from "primereact/carousel";

import NMT from "../../img/NMT.png";
import usgs from "../../img/usgs.png";
import NMBGMR from "../../img/NMBGMR.gif";
import NMOSE from "../../img/NMOSE_ISC.png";

const DataSourceComponent = () => {
  const dataSources = [
    {
      name: "NMOSE",
      display: "New Mexico Office of the State Engineer",
      image: NMOSE,
      homepage: "https://www.ose.nm.gov/",
      url: "https://www.ose.nm.gov/",
    },
    {
      name: "USGS",
      display: "US Geological Survey",
      image: usgs,
      url: "https://waterservices.usgs.gov/",
      homepage: "https://www.usgs.gov/",
    },
    {
      name: "NMBGMR",
      display: "New Mexico Bureau of Geology and Mineral Resources",
      image: NMBGMR,
      homepage: "https://geoinfo.nmt.edu/",
      url: "https://waterdata.nmt.edu/",
    },
  ];
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const productTemplate = (datasouce) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center">
        <div className="mb-3 p-4 h-10rem">
          <a href={datasouce.url} target="_blank">
            <img
              src={datasouce.image}
              alt={datasouce.name}
              className="w-8rem h-auto shadow-2"
            />
          </a>
        </div>
        <div>
          <h5 className="m-2">
            <a href={datasouce.homepage} target="_blank">
              {datasouce.display}
            </a>
          </h5>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <h4 className="text-center">Our Data Sources </h4>
      <Carousel
        value={dataSources}
        numVisible={4}
        numScroll={4}
        responsiveOptions={responsiveOptions}
        itemTemplate={productTemplate}
      />
    </div>
  );
};

export default DataSourceComponent;
// ============= EOF =============================================
