// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import Papa from "papaparse";
import { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { downloadCSV } from "../download_util";

export default function BatchManualWaterLevelsUpload() {
  const [previewData, setPreviewData] = useState([]);
  const fileUploadRef = useRef();

  const handleDownloadTemplate = () => {
    let rows = [["PointID", "MeasurementDatetime", "DepthToWater"]];
    downloadCSV("manual_water_levels_template", rows);
  };

  const onSelect = (event) => {
    console.log("onUpload", event);
    var fileReader = new FileReader();
    fileReader.onload = function () {
      let txt = fileReader.result;
      let pd = Papa.parse(txt, { header: true });
      setPreviewData(pd.data);
    };
    fileReader.readAsText(event.files[0]);
  };
  const onClear = () => {
    setPreviewData([]);
  };

  return (
    <div>
      <h1>Well Upload</h1>
      <Button onClick={handleDownloadTemplate}>Download Example</Button>
      <FileUpload
        ref={fileUploadRef}
        name="file"
        onSelect={onSelect}
        onRemove={onClear}
        onClear={onClear}
        accept="application/text"
        maxFileSize={10000000}
        emptyTemplate={
          <p className="m-0">Drag and drop CSV file here to upload.</p>
        }
      />
      <DataTable value={previewData}>
        <Column field={"PointID"} header={"Point ID"} />
        <Column field={"MeasurementDatetime"} header={"Measurement Datetime"} />
        <Column field={"DepthToWater"} header={"Depth To Water"} />
      </DataTable>
    </div>
  );
}
// ============= EOF =============================================
