// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";

import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";

import { nmbgmr_getJson } from "../../util";
import { useFiefTokenInfo } from "@fief/fief/react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { Chip } from "primereact/chip";

const Plot = createPlotlyComponent(Plotly);

export default function AMPAPIHydrograph({
  pointId,
  setHydrographData,
  showTable = false,
  showDisclaimer = true,
  setLoading,
  useContinousData = false,
}) {
  const [manualData, setManualData] = useState([]);
  const [allData, setAllData] = useState([]);
  const tokenInfo = useFiefTokenInfo();
  const [data, setData] = useState([]);
  const [plotData, setPlotData] = useState([]);
  const [showContinousData, setShowContinousData] = useState(useContinousData);
  const [layout, setLayout] = useState({
    title: `Hydrograph ${pointId}`,
    height: 600,
    width: 850,
    // width: showTable ? 800 : 1200,
    // autosize: true,
    xaxis: { title: "Date" },
    yaxis: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  });
  const [lloading, setLLoading] = useState(false);

  const auth_api_getJson = (url) => {
    return nmbgmr_getJson(url, tokenInfo?.access_token);
  };

  const makePlotlySeries = (items, name) => {
    let x = items.map((item) => {
      // if (item.TimeMeasured != null) {
      //   return item.DateMeasured + " " + item.TimeMeasured;
      // } else {
      //   return item.DateMeasured + " 00:00";
      // }
      return item.DateTimeMeasured;
    });
    let y = items.map((item) => {
      return item.DepthToWaterBGS;
    });

    return { x: x, y: y, mode: "lines+markers", name: name };
  };

  // const makeChartSeries = (items, name) => {
  //   console.log(items);
  //
  //   return {
  //     data: items.map((item) => ({
  //       x: item.TimeMeasured
  //         ? item.DateMeasured + " " + item.TimeMeasured
  //         : item.DateMeasured,
  //       y: item.DepthToWaterBGS,
  //       label: name,
  //     })),
  //   };
  // };

  useEffect(() => {
    console.log("get data for selected", pointId);
    console.log("data", data);
    // if (data === undefined || data.length === 0) {
    if (setLoading !== undefined) {
      setLoading(true);
    }
    setLayout({ ...layout, title: `Hydrograph ${pointId}` });

    setLLoading(true);
    const url = `waterlevels?pointid=${pointId}`;
    const continuousNames = ["Transducer", "Continuous acoustic sounder"];
    auth_api_getJson(url).then((data) => {
      data = data.map((item) => {
        item.DateTimeMeasured = item.DateMeasured;
        if (item.TimeMeasured != null) {
          item.DateTimeMeasured += " " + item.TimeMeasured;
        } else {
          item.DateTimeMeasured += " 00:00";
        }
        return item;
      });

      data = data.sort((a, b) => {
        return new Date(b.DateTimeMeasured) - new Date(a.DateTimeMeasured);
      });

      let transducer = data.filter((item) => {
        return continuousNames.includes(item.MeasurementMethod);
      });
      let manual = data.filter((item) => {
        return !continuousNames.includes(item.MeasurementMethod);
      });

      setPlotData([
        makePlotlySeries(transducer, "Continuous"),
        makePlotlySeries(manual, "Manual"),
      ]);

      setManualData(manual);
      setAllData(data);

      console.log("manual", manual);
      if (!showContinousData) {
        setData(manual);
      } else {
        setData(data);
      }

      if (setHydrographData !== undefined) {
        setHydrographData(data);
      }

      // const chartdata = {
      //   datasets: [
      //     makeChartSeries(transducer, "Transducer"),
      //     makeChartSeries(manual, "Manual"),
      //   ],
      // };
      // setData(chartdata);
      if (setLoading !== undefined) {
        setLoading(false);
      }
      setLLoading(false);
    });
    // }
  }, [pointId]);

  useEffect(() => {
    if (!showContinousData) {
      setData(manualData);
    } else {
      setData(allData);
    }
  }, [showContinousData]);
  // const options = {
  //   scales: {
  //     x: { title: "Date" },
  //     y: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  //   },
  // };
  return (
    <div className={"grid"}>
      {lloading && (
        <ProgressSpinner strokeWidth={3} className={"absolute z-5"} />
      )}
      {/*<div className={"flex"}>*/}
      {/*<div className={showTable ? "col-6 border-1" : "col-12"}>*/}
      <div className={"col-12 lg:col-6 overflow-auto"}>
        <Plot
          divId={"hydrograph"}
          data={plotData}
          layout={layout}
          // config={{ responsive: true }}
        />
      </div>
      {/*</div>*/}
      {showTable && (
        <div className={"col-12 lg:col-6"}>
          <div className={"m-2"}>
          <Button
            onClick={(evt) => {
              setShowContinousData(!showContinousData);
            }}
          >
            {(!showContinousData ? "Show" : "Hide") + " Continous Data"}
          </Button>
          </div>
          <DataTable
            value={data}
            size={"small"}
            className={"compact-table"}
            paginator
            rows={20}
            rowsPerPageOptions={[10, 20, 50]}
            // autoLayout
            scrollable
            scrollHeight="500px"
            stripedRows
          >
            {tokenInfo && (
              <Column
                sortable
                body={(rowData) => {
                  return (
                    <Chip
                      key="publicrelease"
                      label={rowData["PublicRelease"] ? "Yes" : "No"}
                      className={
                        "pt-0 pb-0 pr-4 pl-4 cellchip " +
                        (rowData["PublicRelease"] ? "tcellchip" : "fcellchip")
                      }
                    />
                  );
                }}
                header="Public"
              ></Column>
            )}
            <Column
              sortable
              field="DateMeasured"
              header="Date"
              headerTooltip={"Database Field: DateMeasured"}
            />
            {/*<Column field="TimeMeasured" header="TimeMeasured" />*/}
            <Column
              sortable
              field="MeasurementMethod"
              header="Method"
              headerTooltip={"Database Field: MeasurementMethod"}
            />
            <Column field="DepthToWaterBGS" header="Depth to Water (ft bgs)" />
            <Column
              sortable
              field="DataSource"
              header="Source"
              headerTooltip={"Database Field: DataSource"}
            />
            <Column sortable field="MeasuringAgency" header="Agency" />

            {tokenInfo && <Column sortable field="MeasuredBy" header="By" />}
            {tokenInfo && (
              <Column sortable field="SiteNotes" header="SiteNotes" />
            )}
          </DataTable>
          {showDisclaimer && (
            <Panel
              header={"Disclaimer"}
              collapsed
              toggleable
              className={"bg-blue-50 text-sm"}
            >
              <p>
                At NMBGMR, we use different tools to collect groundwater level
                measurements, including continuous data recorders and manual
                measurements. All data provided here are in feet, depth to
                water, below ground surface (BGS). We use pressure transducers
                to record pressure of water over a device installed in the well,
                which is converted to feet of water and depth to water. We
                provide here up to one measurement per hour where the data are
                that frequent. In some locations we have more data available. We
                also use continuous acoustic sounder devices which convert a
                sound reflection into a measurement of depth to water. These can
                be used for long term trends in groundwater levels. While we do
                our best to review and quality check these data, please use
                these data with caution. Site-specific conditions should be
                verified, especially for legally binding decisions. Data are
                subject to changes, deletion, or being moved without notice at
                any time and should not be relied on for any critical
                application. Any opinions expressed may not necessarily reflect
                the official position of the New Mexico Bureau of Geology, New
                Mexico Tech, or the State of New Mexico. No warranty expressed
                or implied, is made regarding the accuracy or utility of the
                data for general or scientific purposes.
              </p>
            </Panel>
          )}
        </div>
      )}
      {/*</div>*/}

      {/*<Chart type="line" data={data} options={options} />*/}
    </div>
  );
}
// ============= EOF =============================================
