// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { InputNumber } from "primereact/inputnumber";

export default function BBoxControl({ bbox, setBbox, enabled }) {
  return (
    <div>
      <div className={"p-fluid grid formgrid"}>
        <label className={"p-3"}>Bounding Box:</label>
        <div className="field col-12 md:col-2">
          <label htmlFor="minLat">Min. Latitude</label>
          <InputNumber
            inputId="minLat"
            value={bbox.minLat}
            disabled={!enabled}
            onValueChange={(e) => setBbox({ minLat: e.value })}
          />
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="maxLat">Max. Latitude</label>
          <InputNumber
            inputId="maxLat"
            value={bbox.maxLat}
            disabled={!enabled}
            onValueChange={(e) => setBbox({ maxLat: e.value })}
          />
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="minLng">Min. Longitude</label>
          <InputNumber
            inputId="minLng"
            value={bbox.minLng}
            disabled={!enabled}
            onValueChange={(e) => setBbox({ minLng: e.value })}
          />
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="maxLng">Min. Longitude</label>
          <InputNumber
            inputId="maxLng"
            value={bbox.maxLng}
            disabled={!enabled}
            onValueChange={(e) => setBbox({ maxLng: e.value })}
          />
        </div>
      </div>
    </div>
  );
}
// ============= EOF =============================================
