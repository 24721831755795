// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import "./NavBar.css";

import { Button } from "primereact/button";
import {
  useFiefAuth,
  useFiefIsAuthenticated,
  useFiefTokenInfo,
  useFiefUserinfo,
} from "@fief/fief/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import nmwdi_logo from "../../img/nmwdi_logo11-23.png";
import { Menubar } from "primereact/menubar";
import { Avatar } from "primereact/avatar";
import { Checkbox } from "primereact/checkbox";
import { sha256 } from "js-sha256";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { jwtDecode } from "jwt-decode";
import {
  batch_manual_waterlevels_upload,
  chem_upload,
  continuous,
  data_view,
  die,
  manual_water_level_entry,
  well_upload,
} from "./SecureMenuItems";
import {
  collabnet,
  maps,
  documentation,
  home,
  tools,
  wells,
  confirm,
  groundwater,
} from "./PublicItems";
import { PrimeReactContext } from "primereact/api";

const validatePermissions = (scopes, ...requiredScopes) => {
  return requiredScopes.every((scope) => scopes.includes(scope));
};

function assembleSecureItems(scopes) {
  let secure_items = [continuous];
  if (validatePermissions(scopes, "manualwaterlevelentry:write")) {
    secure_items.push(manual_water_level_entry);
  }
  if (validatePermissions(scopes, "chem:write")) {
    secure_items.push(chem_upload);
  }
  if (validatePermissions(scopes, "die:user")) {
    secure_items.push(die);
  }
  if (validatePermissions(scopes, "well:read", "location:read", "owner:read")) {
    secure_items.push(wells, data_view);
  }
  if (validatePermissions(scopes, "well:write")) {
    secure_items.push(well_upload);
  }
  if (validatePermissions(scopes, "manualwaterlevel:batch:write")) {
    secure_items.push(batch_manual_waterlevels_upload);
  }
  return secure_items;
}

function AppNavbar() {
  const fiefAuth = useFiefAuth();
  const isAuthenticated = useFiefIsAuthenticated();
  const userinfo = useFiefUserinfo();
  const tokenInfo = useFiefTokenInfo();
  const toast = useRef(null);
  const [scopes, setScopes] = useState(["manualwaterlevelentry:write"]);
  const { changeTheme } = useContext(PrimeReactContext);

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true",
  );

  const logout = useCallback(() => {
    fiefAuth.logout(`${window.location.protocol}//${window.location.host}`);
  }, []);

  const login = useCallback(() => {
    try {
      fiefAuth.redirectToLogin(
        `${window.location.protocol}//${window.location.host}/callback`,
      );
    } catch (error) {
      console.log("login error", error);

      confirmDialog({
        message: "There was an error logging in. Please try again.",
        header: "Login Error",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          console.log("accept");
        },
      });
      fiefAuth.storage.clearUserinfo();
      fiefAuth.storage.clearTokeninfo();
    }
  }, [fiefAuth]);

  let [loginlabel, onClick] =
    isAuthenticated && userinfo ? ["Logout", logout] : ["Login", login];

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString());
  }, [darkMode]);

  const [iconClassName, setIconClassName] = useState(
    localStorage.getItem("darkMode") === "true" ? "pi-moon" : "pi-sun",
  );

  // return (<Button  onClick={()=> setHelpVisible(true)} severity="help" label={"Help"}/>)
  const brand = (
    <a href={"https://newmexicowaterdata.org"}>
      <img src={nmwdi_logo} height="80px" />
    </a>
  );

  useEffect(() => {
    if (!tokenInfo) {
      return;
    }
    const token = jwtDecode(tokenInfo?.access_token);
    if (token?.permissions) {
      console.log("token", token?.permissions);
      setScopes(token?.permissions);
    }
  }, [tokenInfo]);

  const makeTheme = (theme) => {
    return `themes/${theme}/theme.css`;
  };

  const gravatarUrl = (email) => {
    let hash = email.trim().toLowerCase();
    return `https://www.gravatar.com/avatar/${sha256(hash)}`;
  };

  // useEffect(() => {
  //   let [currentTheme, newTheme] = darkMode
  //     ? ["bootstrap4-dark-blue", "bootstrap4-light-blue"]
  //     : ["bootstrap4-light-blue", "bootstrap4-dark-blue"];
  //   changeTheme(makeTheme(currentTheme), makeTheme(newTheme), "theme-link");
  //
  //   // setDarkMode(!darkMode);
  //   setIconClassName(darkMode ? "pi-sun" : "pi-moon");
  // }, [darkMode]);

  let loginout_div = (
    <div>
      {/*disable temporarily until this feature is fully implemented*/}
      {/*<Button*/}
      {/*  onClick={(e) => {*/}
      {/*    // let [currentTheme, newTheme] = darkMode*/}
      {/*    //   ? ["bootstrap4-dark-blue", "bootstrap4-light-blue"]*/}
      {/*    //   : ["bootstrap4-light-blue", "bootstrap4-dark-blue"];*/}
      {/*    // changeTheme(*/}
      {/*    //   makeTheme(currentTheme),*/}
      {/*    //   makeTheme(newTheme),*/}
      {/*    //   "theme-link",*/}
      {/*    // );*/}

      {/*    setDarkMode(!darkMode);*/}
      {/*    // setIconClassName(darkMode ? "pi-sun" : "pi-moon");*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <i className={`dark:text-white pi ${iconClassName}`} />*/}
      {/*</Button>*/}

      <span style={{ padding: "10px" }}></span>

      {isAuthenticated && userinfo ? (
        <Avatar
          label={userinfo?.email.substring(0, 1).toUpperCase()}
          image={gravatarUrl(userinfo?.email)}
          className="mr-2"
          size="large"
        ></Avatar>
      ) : (
        ""
      )}
      <Button label={loginlabel} onClick={onClick} icon={"pi pi-fw pi-user"} />
    </div>
  );

  const ckan = {
    id: "ckan",
    label: "Data Catalog",
    icon: "pi pi-fw pi-cloud",
    command: () => {
      // ;
      {
        localStorage.getItem("hideDataCatalogDecision") === "true"
          ? (window.location.href = "https://catalog.newmexicowaterdata.org")
          : confirm();
      }
    },
  };

  let items = [home];
  if (isAuthenticated && validatePermissions(scopes, "pvacd:read")) {
    const pvacd = {
      id: "pvacd",
      label: "PVACD",
      icon: "pi pi-fw pi-cloud",
      command: () => {
        window.location.href = "/pvacd";
      },
    };
    const amaps = {
      id: "maps",
      label: "Maps",
      icon: "pi pi-fw pi-map",
      items: [groundwater, pvacd],
    };
    items.push(amaps);
  } else {
    items.push(maps);
  }
  items.push(documentation, ckan, collabnet, tools);

  if (isAuthenticated) {
    const authenticated = {
      id: "secure",
      label: "Secure Area",
      icon: "pi pi-fw pi-exclamation-triangle",
      items: assembleSecureItems(scopes),
    };
    items.push(authenticated);
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Menubar
        model={items}
        start={brand}
        end={loginout_div}
        className={"custom-menubar"}
      />
    </>
  );
}

export default AppNavbar;
// ============= EOF =============================================
