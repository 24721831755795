// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { FileUpload } from "primereact/fileupload";
import { settings } from "../../settings";
import { useFiefTokenInfo } from "@fief/fief/react";
import { Card } from "primereact/card";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";

export default function ChemForm() {
  const tokenInfo = useFiefTokenInfo();
  const toast = useRef(null);
  const fileUploadRef = useRef(null);
  const onError = (event) => {
    fileUploadRef.current.clear();

    let message = JSON.parse(event.xhr.response).detail;
    console.log("there wasd ana error", event);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  };
  const onClear = () => {
    fileUploadRef.current.setFiles([]);
    fileUploadRef.current.setUploadedFiles([]);
  };
  const onBeforeSend = (event) => {
    // add auth header
    event.xhr.setRequestHeader(
      "Authorization",
      `Bearer ${tokenInfo?.access_token}`,
    );
  };

  const chooseOptions = { label: "Choose", icon: "pi pi-fw pi-plus" };
  const uploadOptions = {
    label: "Uplaod",
    icon: "pi pi-upload",
    className: "p-button-success",
  };
  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  // const itemTemplate = (file, props) => {
  //   return (
  //     <div className="flex align-items-center flex-wrap">
  //       <div className="flex align-items-center" style={{ width: "40%" }}>
  //         {/*<img*/}
  //         {/*  alt={file.name}*/}
  //         {/*  role="presentation"*/}
  //         {/*  src={file.objectURL}*/}
  //         {/*  width={100}*/}
  //         {/*/>*/}
  //         <span className="flex flex-column text-left ml-3">
  //           {file.name}
  //           <small>{new Date().toLocaleDateString()}</small>
  //         </span>
  //       </div>
  //       <Tag
  //         value={props.formatSize}
  //         severity="warning"
  //         className="px-3 py-2"
  //       />
  //       <Button
  //         type="button"
  //         icon="pi pi-times"
  //         className="p-button-outlined p-button-rounded p-button-danger ml-auto"
  //         onClick={() => props.onRemove()}
  //       />
  //     </div>
  //   );
  // };
  const onUpload = (event) => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };
  return (
    <div className={"grid"}>
      <Toast ref={toast}></Toast>
      <div className={"col-12"}>
        <Card>
          <h4>Upload LIMS Excel file</h4>
          <p>
            Upload a LIMS Excel file to add water chemistry data to the
            database.
          </p>
        </Card>
      </div>

      <div className={"col-5 col-offset-4"}>
        <FileUpload
          ref={fileUploadRef}
          name="file"
          url={`${settings.nmbgmr_api_url}authorized/waterchemistry/limsupload`}
          // multiple
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
          onError={onError}
          onClear={onClear}
          onBeforeSend={onBeforeSend}
          onUpload={onUpload}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          maxFileSize={10000000}
          // headerTemplate={headerTemplate}
          // itemTemplate={itemTemplate}
          emptyTemplate={
            <p className="m-0">
              Drag and drop LIMS Excel files here to upload.
            </p>
          }
        />
      </div>
    </div>
  );
}
// ============= EOF =============================================
