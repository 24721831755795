import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";

// only initialize if in production
// if (process.env.NODE_ENV === "production") {
//   ReactGA.initialize("G-3SSZ4P5HF3");
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// const SendAnalytics = () => {
//   console.debug("sending analytics");
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// };
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(SendAnalytics);
reportWebVitals();
