// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { retrieveItems } from "../../../util";
import { ProgressSpinner } from "primereact/progressspinner";

export default function GenericHydrograph({ location }) {
  const [plotData, setPlotData] = useState([]);
  const [layout, setLayout] = useState({
    height: 600,
    width: 850,
    xaxis: { title: "Date" },
    yaxis: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  });
  const [loading, setLoading] = useState(false);

  const getst2Data = (iotid) => {
    setLoading(true);
    let url =
      `https://st2.newmexicowaterdata.org/FROST-Server/v1.1/` +
      `Locations(${iotid})?$expand=Things/Datastreams`;
    fetch(url).then((response) => {
      response.json().then((data) => {
        let ds = data.Things[0].Datastreams[0];

        let obsUrl =
          "https://st2.newmexicowaterdata.org/FROST-Server/v1.1/" +
          `Datastreams(${ds["@iot.id"]})/Observations?$orderby=phenomenonTime desc`;

        retrieveItems(obsUrl, []).then((data) => {
          setLoading(false);
          setPlotData([
            {
              x: data.map((s) => s.phenomenonTime),
              y: data.map((s) => s.result),
            },
          ]);
        });
      });
    });
  };
  useEffect(() => {
    if (!location) return;

    setLayout({ ...layout, title: `${location.properties.name}` });
    switch (location.data_source) {
      default: // case "weaver:gw:ebid", "weaver:gw:bernco", "weaver:gw:pvacd"
        getst2Data(location.properties.iotid);
        break;
    }
  }, [location]);

  return (
    <div className={"relative"}>
      <div className={"absolute bottom-50 right-50 z-1"}>
        {loading && <ProgressSpinner strokeWidth={5} />}
      </div>
      <Plot
        data={plotData}
        layout={layout}
        // config={{ responsive: true }}
      />
    </div>
  );
}
// ============= EOF =============================================
