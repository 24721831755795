// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Button } from "primereact/button";
import axios from "axios";
// {agency: 'PVACD', source_id: 4745648669458432}

let wells = [
  {
    pointid: "NM-28258",
    name: "Zumwalt level",
    elevation: 3459,
    welldepth: 950,
    holedepth: 0,
    id: 4538855792574464,
  },
  {
    pointid: "NM-28255",
    name: "Greenfield level",
    elevation: 880,
    welldepth: 880,
    holedepth: 0,
    id: 5830701895778304,
  },
  {
    pointid: "NM-28250",
    name: "Poe Corn Level",
    elevation: 3622,
    welldepth: 435,
    holedepth: 0,
    id: 6054555505917952,
  },
  {
    pointid: "NM-28253",
    name: "LFD Level ",
    elevation: 512,
    welldepth: 512,
    holedepth: 0,
    id: 5597309948919808,
  },
  {
    pointid: "NM-28254",
    name: "Orchard Park Level",
    elevation: 3539,
    welldepth: 930,
    holedepth: 930,
    id: 6505900885147648,
  },
  {
    pointid: "NM-28256",
    name: "Bartlett level",
    elevation: 1150,
    welldepth: 1150,
    holedepth: 0,
    id: 4745648669458432,
  },
  {
    pointid: "NM-28259",
    name: "Artesia A Level",
    elevation: 3402,
    welldepth: 726,
    holedepth: 1008,
    id: 6256156690612224,
  },
  {
    pointid: "NM-28257",
    name: "Cottonwood level",
    elevation: 3529,
    welldepth: 950,
    holedepth: 0,
    id: 4803999894339584,
  },
  {
    pointid: "NM-28251",
    name: "Transwestern Level",
    elevation: 3618,
    welldepth: 352,
    holedepth: 0,
    id: 4586726273318912,
  },
  {
    pointid: "NM-28252",
    name: "Berrendo-Smith level",
    elevation: 3581,
    welldepth: 329,
    holedepth: 0,
    id: 4847162637942784,
  },
];

export default function SimpleEditor() {
  const submit = () => {
    console.log("submit");
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: "write",
        password: "write",
      },
    };

    wells.forEach((well) => {
      let url =
        "https://st2.newmexicowaterdata.org/FROST-Server/v1.1/Locations";
      url = `${url}?$filter=name eq '${well.name}'&$expand=Things`;
      console.log(url);
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log(well, data);
          let location = data.value[0];
          let url = location["@iot.selfLink"];
          console.log("update location", url);
          console.log(location);
          let coordinates = [
            location.location.coordinates[0],
            location.location.coordinates[1],
            well.elevation * 0.3048,
          ]; // elevation in meters
          axios.patch(
            url,
            { location: { type: "Point", coordinates: coordinates } },
            config,
          );

          let thing = location.Things[0];
          url = thing["@iot.selfLink"];
          console.log("update thing", url);

          let payload = {};
          payload["agency"] = "PVACD";
          payload["source_id"] = well.id;
          payload["well_depth"] = { value: well.welldepth, unit: "ft" };
          payload["hole_depth"] = { value: well.holedepth, unit: "ft" };
          payload["nmbgmr_pointid"] = well.pointid;
          console.log(payload);
          axios.patch(url, { properties: payload }, config);

          // fetch(url)
          //   .then((response) => response.json())
          //   .then((data) => {
          //     let payload = {};
          //     payload["agency"] = "PVACD";
          //     payload["source_id"] = well.id;
          //     payload["well_depth"] = well.welldepth;
          //     payload["hole_depth"] = well.holedepth;
          //
          //     console.log(payload);
          //     axios.patch(url, { properties: payload }, config);
          //   });
        });
    });
  };

  return (
    <div>
      <h1>Simple Editor</h1>

      <Button label={"Submit"} onClick={submit} />
    </div>
  );
}
// ============= EOF =============================================
