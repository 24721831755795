// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import "mapbox-gl/dist/mapbox-gl.css";
import "./Map.css";

import { settings } from "../../settings";
import { Layer, Map, NavigationControl, Popup, Source } from "react-map-gl";
import { useCallback, useEffect, useRef, useState } from "react";
import DrawControl from "./DrawControl";
import { ProgressSpinner } from "primereact/progressspinner";
import GeocoderControl from "./GeocoderControl";
import { ContextMenu } from "primereact/contextmenu";
import * as turf from "@turf/turf";
import { isTouchDevice } from "../../util";

export const extractFeatures = (sourceData, searchPolygon, useAllFeatures) => {
  let selected = [];
  if (searchPolygon === null) {
    return selected;
  }
  //get all features in selected polygons
  for (let [key, source] of Object.entries(sourceData)) {
    let filteredFeatures = source.features;
    if (useAllFeatures !== true) {
      filteredFeatures = filteredFeatures.filter((f) =>
        turf.booleanPointInPolygon(f.geometry, searchPolygon),
      );
    }

    console.debug("filteredFeatures", filteredFeatures);
    let ff = filteredFeatures.map((feature) => {
      return {
        ...feature,
        data_source: source.properties.data_source,
        url: source.properties.url,
      };
    });
    selected.push(...ff);
  }
  return selected;
};

export const getSelectedFeatures = (
  options,
  sourceData,
  layerVisibility,
  selectionPolygons,
) => {
  let allFeatures = [];
  let sd = Object.keys(sourceData)
    .filter((key) => layerVisibility[key] === "visible")
    .reduce((res, key) => ((res[key] = sourceData[key]), res), {});

  if (
    Object.keys(selectionPolygons).length > 0 &&
    options.all_locations !== true
  ) {
    for (const [key, polygon] of Object.entries(selectionPolygons)) {
      allFeatures.push(...extractFeatures(sd, polygon, options.all_locations));
    }
  } else {
    allFeatures = extractFeatures(sd, null, options.all_locations);
  }
  console.debug("all features", allFeatures);
  return allFeatures;
};

export default function MapComponent({
  sources = [],
  selectionPolygons,
  setSelectionPolygons,
  sourceData,
  setSourceData,
  onMouseMoveCallback,
  onMouseClickCallback,
  onContextMenuCallback,
  mapStyle = "mapbox://styles/mapbox/satellite-streets-v11",
  mapRef,
  popupContent,
  setPopupContent,
  stickyPopup,
  setStickyPopup,
  // highlightedPoint,
  showDrawControls = { show: false, position: "top-right" },
  showNavigation = { show: true, position: "top-right" },
  showGeocoder = { show: true, position: "top-left" },
  style = { width: "100%", height: "650px" },
  property_identifier,
  layerVisibility,
  dynamicLayers,
  defaultFeatures,
  initialViewState,
}) {
  // const mapRef = useRef();
  // const [mapStyle, setMapStyle] = useState(
  //   "mapbox://styles/mapbox/satellite-streets-v11",
  // );
  const [loading, setLoading] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  // const [popupContent, setPopupContent] = useState(undefined);
  const [isDrawing, setIsDrawing] = useState(false);

  const cmRef = useRef();
  
  //Check for mobile user
  const isMobile = isTouchDevice();

  const mapContextMenu = [
    {
      label: "Detail View",
      icon: "pi pi-fw pi-info-circle",
      command: (e) => {
        console.log("detail view", e);
        let sel = getCurrentPoints(e)[1];
        if (sel.length === 0) {
          return;
        }
        sel = sel[0];
        window.open("/location/" + sel.properties.name, "_blank");
      },
    },
  ];
  // start draw controls handlers
  const onUpdate = useCallback((e) => {
    setSelectionPolygons((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e) => {
    setSelectionPolygons((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const onModeChange = useCallback((e) => {
    console.log("mode", e);
    setIsDrawing(e.mode === "draw_polygon");
  }, []);

  const onselectionChange = useCallback((e) => {
    console.log("selection change", e);
    setIsDrawing(e.features.length > 0);
  }, []);
  // end draw controls handlers

  // start mouse handlers
  const getCurrentPoints = (e) => {
    if (sources.length === 0) {
      return [[], []];
    }
    if (!mapRef || !mapRef.current) {
      return [[], []];
    }

    const sourceids = sources.map((s) => s.id);
    let features = mapRef.current.queryRenderedFeatures(e.point);

    let prop = property_identifier;
    if (prop === undefined) {
      prop = "name";
    }

    features = features.filter(
      (f) => f.type === "Feature" && sourceids.includes(f.source),
    );
    return [
      features,
      features.map((f) => {
        if (sourceData[f.source].features === undefined) {
          return;
        }
        // console.log("ff", f, sourceData[f.source].features);
        return sourceData[f.source].features.find(
          (ff) => ff.properties[prop] === f.properties[prop],
        );
      }),
    ];
  };

  const onMouseMove = (e) => {
    if (mapRef === undefined) {
      return;
    }
    if (isDrawing === true) {
      return;
    }

    const [features, selected_points] = getCurrentPoints(e);
    if (selected_points === undefined || selected_points.length === 0) {
      mapRef.current.getCanvas().style.cursor = "grab";
      if (popupContent !== undefined) {
        if (stickyPopup === true) {
          return;
        }
      }
      if (setPopupContent != undefined) {
        setPopupContent(undefined);
      }
      return;
    }
    mapRef.current.getCanvas().style.cursor = "pointer";
    if (onMouseMoveCallback !== undefined) {
      setStickyPopup(false);
      onMouseMoveCallback(e, features, selected_points);
    }
  };

  const onMouseClick = (e) => {
    if (isDrawing === true) {
      return;
    }
    const [features, selected_points] = getCurrentPoints(e);
    if (selected_points === undefined || selected_points.length === 0) {
      return;
    }
    if (onMouseClickCallback !== undefined) {
      onMouseClickCallback(e, selected_points);
    }
  };

  //Touch event handler for mobile to simulate mouse hover
  const onTouchClick = (e) => {
    const [features, selected_points] = getCurrentPoints(e);
    if (selected_points === undefined || selected_points.length === 0) {
      setPopupContent(undefined);
      mapRef.current.getCanvas().style.cursor = "grab";
    } else {
      onMouseMove(e);  
    }
  };

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map && isMobile) {
      map.on("touchstart", onTouchClick);

      return () => {
        map.off("touchstart", onTouchClick);
      };
    }
  }, [mapRef, onTouchClick]);

  const onContextMenu = (e) => {
    if (isDrawing === true) {
      return;
    }
    const [features, selected_points] = getCurrentPoints(e);
    if (selected_points === undefined || selected_points.length === 0) {
      return;
    }
    if (onContextMenuCallback !== undefined) {
      onContextMenuCallback(e, selected_points);
    }
    cmRef.current.show(e.originalEvent);
  };
  // end mouse handlers
  // build layers and sources
  const makeLayerSource = (source) => {
    if (sourceData[source.id] === undefined) {
      return;
    }

    if (layerVisibility === undefined) {
      layerVisibility = {};
    }

    let vis = "visible";
    if (layerVisibility[source.id] !== undefined) {
      vis = layerVisibility[source.id];
    }

    let layers;
    if (source.layers !== undefined) {
      layers = source.layers.map((layer) => {
        return (
          <Layer
            key={source.id + layer.id}
            id={source.id + layer.id}
            type={layer.type || "circle"}
            properties={{ foo: "bar" }}
            layout={{ visibility: vis }}
            paint={layer.paint}
          />
        );
      });
    } else {
      layers = [
        <Layer
          id={source.id}
          key={source.id}
          type={source.layer_type || "circle"}
          properties={{ foo: "bar" }}
          layout={{ visibility: vis }}
          paint={
            source.paint || {
              "circle-radius": 4,
              "circle-color": "#B42222",
              "circle-stroke-width": 1,
              "circle-stroke-color": "#000000",
            }
          }
        />,
      ];
    }

    return (
      <Source
        key={source.id}
        id={source.id}
        type={source.type || "geojson"}
        data={sourceData[source.id]}
      >
        {layers}
      </Source>
    );
  };

  // load data from APIs
  useEffect(() => {
    //load each source
    if (setSourceData === undefined) {
      return;
    }

    for (const source of sources) {
      // console.log(
      //   "asdfasd",
      //   source.id,
      //   Object.keys(sourceData),
      //   sourceData[source.id],
      // );
      //only load data once
      if (sourceData[source.id] !== undefined) {
        continue;
      }
      setLoading(true);
      source.dataFetcher().then((data) => {
        if (data === undefined) {
          setLoading(false);
          return;
        }
        console.log("source data", source.url, source.id, data);
        data["properties"] = { data_source: source.id, url: source.url };
        setSourceData((prevData) => {
          return { ...prevData, [source.id]: data };
        });

        setLoading(false);
      });
    }
  }, [sources]);

  const layers = sources.map(makeLayerSource);

  if (initialViewState === undefined) {
    initialViewState = {
      longitude: -106.4,
      latitude: 34.5,
      zoom: 6,
    };
  }
  return (
    <div className={"flex align-items-center justify-content-center"}>
      <Map
        ref={mapRef}
        mapboxAccessToken={settings.mapbox.token}
        initialViewState={initialViewState}
        // onClick={(e) => (
        //     console.log('map click', e)
        // )}
        // fog={{
        //   range: [0.8, 8],
        //   // "color": "#f3dddd",
        //   "horizon-blend": 0.05,
        //   "high-color": "#245bde",
        //   "space-color": "#000000",
        //   "star-intensity": 0.95,
        // }}
        terrain={{ source: "mapbox-dem", exaggeration: 3 }}
        projection={"globe"}
        style={style}
        mapStyle={mapStyle}
        onMouseMove={onMouseMove}
        onClick={onMouseClick}
        onContextMenu={onContextMenu}
      >
        {layers}
        {dynamicLayers}

        <ContextMenu model={mapContextMenu} ref={cmRef} />

        {showNavigation?.show && (
          <NavigationControl position={showNavigation?.position} />
        )}
        {showDrawControls?.show && (
          <DrawControl
            displayControlsDefault={false}
            controls={{
              polygon: true,
              trash: true,
              combine_features: true,
              uncombine_features: true,
            }}
            defaultFeatures={defaultFeatures}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onModeChange={onModeChange}
            onSelectionChange={onselectionChange}
            position={showDrawControls?.position}
          />
        )}

        {popupContent !== undefined && (
          <Popup
            latitude={popupContent.coordinates[1]}
            longitude={popupContent.coordinates[0]}
            maxWidth={500}
            closeButton={false}
            // closeOnMove
            closeOnClick
            onClose={() => setStickyPopup(false)}
          >
            {popupContent.children}
          </Popup>
        )}
        {showGeocoder?.show && (
          <GeocoderControl
            mapboxAccessToken={settings.mapbox.token}
            position={showGeocoder?.position}
          />
        )}
        {/*<Source*/}
        {/*  id={"highlightedPoint"}*/}
        {/*  type={"geojson"}*/}
        {/*  data={highlightedPoint}*/}
        {/*>*/}
        {/*  <Layer*/}
        {/*    id={"highlightedPoint"}*/}
        {/*    type={"circle"}*/}
        {/*    paint={{*/}
        {/*      "circle-radius": 6,*/}
        {/*      "circle-color": "transparent",*/}
        {/*      "circle-stroke-color": "#e7df60",*/}
        {/*      "circle-stroke-width": 3,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Source>*/}
      </Map>
      {loading && (
        <div className={"absolute bottom-10"}>
          <ProgressSpinner strokeWidth="8" />
        </div>
      )}
    </div>
  );
}
// ============= EOF =============================================
