// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { AutoComplete } from "primereact/autocomplete";
import { useEffect, useState } from "react";
import { nmbgmr_getJson } from "../util";
import { useFiefTokenInfo } from "@fief/fief/react";
import { debounce } from '../util';

export default function PointIDSelector({
  pointID,
  setPointID,
  setParentFilteredSuggestions,
  onSelect
}) {
  const tokenInfo = useFiefTokenInfo();
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(pointID);

  useEffect(() => {
    setInputValue(pointID);
    searchPointIDs({ query: pointID });
  }, [pointID]);

  const searchPointIDs = debounce((e) => {
    nmbgmr_getJson(
      `authorized/locations/pointids?pointid_like=${e.query}`,
      tokenInfo.access_token,
    ).then((data) => {
      if (Array.isArray(data)) {
        setFilteredSuggestions(data);
        if (setParentFilteredSuggestions !== undefined) {
          setParentFilteredSuggestions(data);
        }
  
        if (data.length === 1) {
          const singleSuggestion = data[0];
          setPointID(singleSuggestion);
          if (onSelect) {
            onSelect(singleSuggestion);
          }
        }
      } else {
        setFilteredSuggestions([]);
        if (setParentFilteredSuggestions !== undefined) {
          setParentFilteredSuggestions([]);
        }
      }
    }).catch((error) => {
      console.error("Error fetching PointIDs:", error);
      setFilteredSuggestions([]);
      if (setParentFilteredSuggestions !== undefined) {
        setParentFilteredSuggestions([]);
      }
    });
  }, 200);

  const handleChange = (e) => {
    setPointID(e.value);
    setInputValue(e.value);
  };

  const handleSelect = (e) => {
    if (e.value) {
      setPointID(e.value);
      setInputValue(e.value);
      if (onSelect) {
        onSelect(e.value);
      }
    }
  };

  return (
    <AutoComplete
      placeholder="Search for a PointID..."
      value={inputValue}
      suggestions={filteredSuggestions}
      completeMethod={searchPointIDs}
      onChange={handleChange}
      onSelect={handleSelect}
      dropdown
      dropdownMode={"current"}
    />
  );
}
// ============= EOF =============================================
