import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Checkbox } from "primereact/checkbox";

export default function DownloadControl({ downloader }) {
  const [downloadConfig, setDownloadConfig] = useState({
    format: "CSV",
    latest_only: true,
    location_info: false,
    all_locations: false,
    water_levels_time_series: false,
  });

  return (
    <div>
      <div style={{ paddingBottom: "10px" }}>
        <Message
          severity={"info"}
          text={
            "Draw a polygon on the map to select the data you want to download"
          }
        />
      </div>

      <div>
        <Checkbox
          inputId="location_info"
          name="location_info"
          onChange={(e) => {
            setDownloadConfig({ ...downloadConfig, location_info: e.checked });
          }}
          checked={downloadConfig.location_info}
        />
        <label htmlFor="location_info" className="ml-2">
          Location Metadata
        </label>
      </div>
      <div>
        <Checkbox
          disabled={downloadConfig.location_info !== true}
          inputId="all_locations"
          name="all_locations"
          onChange={(e) => {
            setDownloadConfig({ ...downloadConfig, all_locations: e.checked });
          }}
          checked={downloadConfig.all_locations}
        />
        <label htmlFor="all_locations" className="ml-2">
          All Locations
        </label>
      </div>

      <hr />
      {/* Water Levels */}
      {/*<div>*/}
      {/*  <Checkbox*/}
      {/*    inputId="water_levels_time_series"*/}
      {/*    name="water_levels_time_series"*/}
      {/*    onChange={(e) => {*/}
      {/*      setDownloadConfig({*/}
      {/*        ...downloadConfig,*/}
      {/*        water_levels_time_series: e.checked,*/}
      {/*      });*/}
      {/*    }}*/}
      {/*    checked={downloadConfig.water_levels_time_series}*/}
      {/*  />*/}
      {/*  <label htmlFor="water_levels_time_series" className="ml-2">*/}
      {/*    Water Level Time Series*/}
      {/*  </label>*/}
      {/*</div>*/}
      {/*<div className="flex">*/}
      {/*  <Checkbox*/}
      {/*    inputId="latest_only"*/}
      {/*    name="latest_only"*/}
      {/*    onChange={(e) => {*/}
      {/*      setDownloadConfig({ ...downloadConfig, latest_only: e.checked });*/}
      {/*    }}*/}
      {/*    checked={downloadConfig.latest_only}*/}
      {/*  />*/}
      {/*  <label htmlFor="latest_only" className="ml-2">*/}
      {/*    Latest Measurement Only*/}
      {/*  </label>*/}
      {/*</div>*/}

      <Dropdown
        placeholder={"Select a download format"}
        options={["CSV", "TSV"]}
        /*"GeoJSON", "JSON", "XML"]*/
        value={downloadConfig.format}
        onChange={(e) =>
          // setSelectedFormat(e.value)
          setDownloadConfig({ ...downloadConfig, format: e.value })
        }
      />
      <Button
        label={"Download"}
        onClick={(e) => {
          downloader(downloadConfig);
        }}
      />
    </div>
  );
}
