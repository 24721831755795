// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const defaultNumberComparatorOptions = [
  "less than",
  "greater than",
  "equal",
  "not equal",
];
const defaultStringComparatorOptions = ["starts with", "ends with", "contains"];

function FilterItem({ tag, attributes, setFilters }) {
  const [attribute, setAttribute] = useState("well_depth.value");
  const [comparator, setComparator] = useState("less than");
  const [value, setValue] = useState("");
  const [comparatorOptions, setComparatorOptions] = useState(
    defaultNumberComparatorOptions,
  );

  useEffect(() => {
    setFilters((prev) => {
      return { ...prev, [tag]: { attribute, comparator, value } };
    });
  }, [attribute, comparator, value]);

  useEffect(() => {
    if (attribute === "name") {
      setComparator(defaultStringComparatorOptions[0]);
      setComparatorOptions(defaultStringComparatorOptions);
    } else {
      setComparator(defaultNumberComparatorOptions[0]);
      setComparatorOptions(defaultNumberComparatorOptions);
    }
  }, [attribute]);
  return (
    <div>
      <Dropdown
        tooltip={"Select an attribute to filter by."}
        tooltipOptions={{ showDelay: 500 }}
        value={attribute}
        options={attributes}
        onChange={(e) => setAttribute(e.value)}
      />
      <Dropdown
        tooltip={"Select a comparison operator."}
        tooltipOptions={{ showDelay: 500 }}
        value={comparator}
        options={comparatorOptions}
        onChange={(e) => setComparator(e.value)}
      />
      <InputText value={value} onChange={(e) => setValue(e.target.value)} />
    </div>
  );
}

export default function FilterControl({ attributes, onFilter }) {
  const [filters, setFilters] = useState({});

  return (
    <div>
      {/*{filters}*/}
      <FilterItem tag="a" attributes={attributes} setFilters={setFilters} />
      <Button label={"Filter"} onClick={(e) => onFilter(filters)} />
    </div>
  );
}
// ============= EOF =============================================
