// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const toKeyValueRows = (data, excludes = []) => {
  if (data === undefined) {
    return [];
  }
  return Object.keys(data)
    .filter((key) => !excludes.includes(key))
    .map(function (key) {
      return { key: key, value: data[key] };
    });
};

export const makeOSEData = (data, excludes = []) => {
  if (data === undefined || data === null) {
    return [];
  }
  return Object.keys(data)
    .filter((key) => !excludes.includes(key))
    .map(function (key) {
      return {
        key: key,
        value: data[key].value,
        description: data[key].description,
      };
    });
};

export const boldBodyTemplateGen = (key) => {
  return (rowData) => {
    return (
      <div>
        <span className={"font-bold"}>{rowData[key]}</span>
      </div>
    );
  };
};

const valueBodyTemplate = (rowData) => {
  if (rowData["key"] === "Public Release") {
    return (
      <Chip
        key={rowData["value"]}
        label={rowData["value"]}
        className={
          "pt-0 pb-0 pr-4 pl-4 cellchip " +
          (rowData["value"] === "Yes" ? "tcellchip" : "fcellchip")
        }
      />
    );
  } else {
    return <span>{rowData["value"]}</span>;
  }
};

const osevalueBodyTemplate = (rowData) => {
  if (rowData["key"] === "nmwrrs_wrs") {
    return <a href={rowData["value"]}>link</a>;
  } else {
    return <span>{rowData["value"]}</span>;
  }
};

export function OSEKeyValueTable({ value }) {
  return (
    <DataTable
      scrollable
      scrollHeight={"353px"}
      stripedRows
      size={"small"}
      value={value}
      className={"compact-table"}
    >
      <Column field={"key"} header={"Name"} body={boldBodyTemplateGen("key")} />
      <Column field={"value"} header={"Value"} body={osevalueBodyTemplate} />
      <Column field={"description"} header={"Description"} />
    </DataTable>
  );
}

export default function KeyValueTable({ value }) {
  return (
    <DataTable
      scrollable
      scrollHeight={"353px"}
      stripedRows
      resizableColumns
      size={"small"}
      value={value}
      className={"compact-table"}
    >
      <Column field={"key"} header={"Name"} body={boldBodyTemplateGen("key")} />
      <Column field={"value"} header={"Value"} body={valueBodyTemplate} />
    </DataTable>
  );
}
// ============= EOF =============================================
