// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Card } from "primereact/card";
import MapComponent from "../../Map/MapComponent";
import { pvacd_gw_locations } from "../Groundwater/Sources";
import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { mToFt, retrieveItems } from "../../../util";
import Plot from "react-plotly.js";
import { ProgressSpinner } from "primereact/progressspinner";
const sources = [pvacd_gw_locations];

export default function PVACDDashboard() {
  const [sourceData, setSourceData] = useState({});
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [plotData, setPlotData] = useState([]);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();

  const [layout, setLayout] = useState({
    height: "100%",
    width: "100%",
    plot_bgcolor: "",
    paper_bgcolor: "",
    margin: { t: 50, b: 100, l: 50, r: 50, pad: 4 },
    xaxis: { title: "Date" },
    yaxis: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  });

  useEffect(() => {
    console.log("sourceData", sourceData);
    if (!sourceData["weaver:gw:pvacd"]) return;
    console.log("sourceData", sourceData["weaver:gw:pvacd"].features);
    setLocations(sourceData["weaver:gw:pvacd"].features);
  }, [sourceData]);

  useEffect(() => {
    if (selectedLocations) {
      plot_hygrogaphs(selectedLocations);
    }
  }, [selectedLocations]);

  const plot_hygrogaphs = (items) => {
    let promises = items.map((item) => {
      let url = item.properties["selflink"] + "?$expand=Things/Datastreams";
      console.log("uads", item, url);
      return fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let datastreams = data.Things.map((t) => t.Datastreams).flat();
          console.log("datastreams", datastreams);
          let dspromise = datastreams.map((ds) => {
            return retrieveItems(
              ds["@iot.selfLink"] +
                "/Observations?$orderby=phenomenonTime desc",
              [],
            ).then((observations) => {
              return {
                mode: ds.name.includes("Manual") ? "markers" : "lines",
                x: observations.map((s) => s.phenomenonTime),
                y: observations.map((s) => s.result),
                name: item.properties.name,
                // name: item.Thing.Locations[0].name + " " + item.name,
              };
            });
          });
          return Promise.all(dspromise);
        });
    });

    setLoading(true);
    Promise.all(promises)
      .then((data) => {
        console.log("data", data.flat());
        setPlotData(data.flat());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={"grid justify-content-center"}>
      <div className={"grid m-3 md:col-10"}>
        <Card className={"col-12 mb-3 bg-blue-50"}>
          <h1 className={"text-center"}>
            Welcome to the PVACD Groundwater Dashboard
          </h1>
        </Card>
        <Card className={"col-5 mb-3 surface-50"}>
          <MapComponent
            mapRef={mapRef}
            initialViewState={{ longitude: -105.4, latitude: 33.25, zoom: 6 }}
            style={{ width: "100%", height: "400px" }}
            sources={sources}
            sourceData={sourceData}
            setSourceData={setSourceData}
          />
        </Card>
        <Card className={"col-7 mb-3 surface-50"}>
          <DataTable
            size={"small"}
            className={"compact-table"}
            // paginator
            // rows={10}
            value={locations}
            selection={selectedLocations}
            onSelectionChange={(e) => setSelectedLocations(e.value)}
            stripedRows
            // value={flattenSourceData(sourceData)}
          >
            {/*<Column*/}
            {/*  sortable*/}
            {/*  field={"data_source"}*/}
            {/*  header={"Data Source"}*/}
            {/*></Column>*/}
            <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />

            <Column sortable field={"properties.name"} header={"Name"}></Column>
            <Column
              sortable
              field={(item) => item.geometry.coordinates[1]}
              header={"Latitude"}
            ></Column>
            <Column
              sortable
              field={(item) => item.geometry.coordinates[0]}
              header={"Longitude"}
            ></Column>
            <Column
              // filter
              sortable
              field={(item) => mToFt(item.geometry.coordinates[2])}
              header={"Elevation"}
            ></Column>
            <Column
              // filter
              sortable
              field={"properties.well_depth.value"}
              header={"Well Depth"}
            ></Column>
            {/*<Column*/}
            {/*  // filter*/}
            {/*  sortable*/}
            {/*  field={"properties.hole_depth.value"}*/}
            {/*  header={"Hole Depth"}*/}
            {/*></Column>*/}
            {/*<Column field={"source_url"} header={"Source URL"}></Column>*/}
            {/*<Column header="Actions" body={actionButton} />*/}
          </DataTable>
        </Card>
        <Card className={"col-12 surface-50"}>
          <div className={"grid justify-content-center relative"}>
            <div className={"absolute bottom-50 right-50 z-1"}>
              {loading && <ProgressSpinner strokeWidth={5} />}
            </div>
            <Plot
              data={plotData}
              layout={layout}
              config={{ responsive: true }}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
// ============= EOF =============================================
