// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import "./Entry.css";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { nmbgmr_getJson, nmbgmr_postJson } from "../../util";
import { useFiefTokenInfo } from "@fief/fief/react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import PointIDSelector from "../PointIDSelector";
import ManualWaterlLevelMeasurements from "../ManualWaterLevelMeasurements";
import { Panel } from "primereact/panel";
import { Label } from "theme-ui";

export default function ManualWaterLevelForm() {
  const [pointID, setPointID] = useState("MG-030");
  const tokenInfo = useFiefTokenInfo();
  const [availableLevelStatuses, setAvailableLevelStatuses] = useState([]);
  const [availableMethods, setAvailableMethods] = useState([]);
  const [availableQuality, setAvailableQuality] = useState([]);
  const [levelStatus, setLevelStatus] = useState(null);
  const [dataQuality, setDataQuality] = useState(null);
  const [measurementMethod, setMeasurementMethod] = useState(null);
  const [mpheight, setMPHeight] = useState(null); // [feet]
  const [measurementDateTime, setMeasurementDateTime] = useState(null);
  const [depthToWater, setDepthToWater] = useState(null);
  const [notes, setNotes] = useState("");
  const [measuredBy, setMeasuredBy] = useState("");
  const [availablePointIDs, setAvailablePointIDs] = useState([]);

  useEffect(() => {
    for (const [label, callback] of [
      ["level_status", setAvailableLevelStatuses],
      ["measurement_method", setAvailableMethods],
      ["data_quality", setAvailableQuality],
    ]) {
      nmbgmr_getJson(
        `authorized/lookuptable/${label}`,
        tokenInfo.access_token,
      ).then((data) => {
        callback(data);
      });
    }

    // nmbgmr_getJson(
    //   "authorized/lookuptable/level_status",
    //   tokenInfo.access_token,
    // ).then((data) => {
    //   setAvailableLevelStatuses(data);
    // });
    //
    // nmbgmr_getJson(
    //   "authorized/lookuptable/measurement_method",
    //   tokenInfo.access_token,
    // ).then((data) => {
    //   setAvailableMethods(data);
    // });
  }, []);

  useEffect(() => {
    nmbgmr_getJson(
      `authorized/wells?pointid=${pointID}`,
      tokenInfo?.access_token,
    ).then((data) => {
      // console.log("data", data);
      // console.log("asfdasfadsfasd", data.measuring_point_height_ft);
      setMPHeight(data?.measuring_point_height_ft);
    });
  }, [pointID]);

  return (
    <div className={"grid"}>
      <div className={"col-12"}>
        <Card>
          <h2>Manual Water Level</h2>
          <p>
            Enter the depth to water below the surface of the ground in feet.
          </p>
        </Card>
      </div>

      <div className={"col-9 col-offset-2"}>
        <Label>PointID</Label>
        <div>
          <PointIDSelector
            pointID={pointID}
            setPointID={setPointID}
            setParentFilteredSuggestions={setAvailablePointIDs}
          />
        </div>
        <div>
          <Label>Measurement Date/Time</Label>
          <Calendar
            hourFormat={"12"}
            showTime
            placeholder={"Measurement Date/Time"}
            value={measurementDateTime}
            onChange={(e) => setMeasurementDateTime(e.value)}
          ></Calendar>
        </div>
        <div>
          <Label>Level Status</Label>
          <Dropdown
            value={levelStatus}
            onChange={(e) => setLevelStatus(e.value)}
            placeholder={"Select a Level Status..."}
            optionLabel={"Meaning"}
            options={availableLevelStatuses}
          />
        </div>
        <div>
          <Label>Measurement Method</Label>
          <Dropdown
            value={measurementMethod}
            onChange={(e) => setMeasurementMethod(e.value)}
            placeholder={"Select a Method..."}
            optionLabel={"Meaning"}
            options={availableMethods}
          />
        </div>
        <div>
          <Label>Data Quality</Label>
          <Dropdown
            value={dataQuality}
            onChange={(e) => setDataQuality(e.value)}
            placeholder={"Select a Quality..."}
            optionLabel={"Meaning"}
            options={availableQuality}
          />
        </div>
        <div>
          <Label>Depth to Water (ft)</Label>
          <InputNumber
            placeholder="Enter a depth to water in feet..."
            value={depthToWater}
            minFractionDigits={0}
            maxFractionDigits={2}
            onChange={(e) => setDepthToWater(e.value)}
          />
        </div>
        <div>
          <Label>Measuring Point Height (ft)</Label>
          <InputNumber
            placeholder="Enter Measuring Point Height in feet..."
            value={mpheight}
            minFractionDigits={0}
            maxFractionDigits={2}
            onChange={(e) => setMPHeight(e.value)}
          />
        </div>
        <div>
          <InputTextarea
            placeholder="Enter notes..."
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div>
          <InputText
            value={measuredBy}
            onChange={(e) => setMeasuredBy(e.target.value)}
            placeholder="Measured By (initals of field personnel)"
          />
        </div>
        <div>
          <Button
            label="Submit"
            onClick={(e) => {
              console.log("asdf", levelStatus);

              let payload = {
                pointid: pointID,
                date: measurementDateTime,
                level_status: levelStatus.Code,
                data_quality: dataQuality.Code,
                measurement_method: measurementMethod.Code,
                mpheight: mpheight,
                depthtowater: depthToWater,
                sitenotes: notes,
                measured_by: measuredBy,
              };
              console.log("payload", payload);
              nmbgmr_postJson(
                "authorized/waterlevels/add/manual",
                payload,
                tokenInfo.access_token,
              ).then(
                (response) => {
                  console.log("response", response);
                },
                (error) => {
                  console.log("error", error);
                },
              );
            }}
          />
        </div>
      </div>
      <div className={"col-12"}>
        <Panel header={"Existing Manual Measurements"} toggleable collapsed>
          <ManualWaterlLevelMeasurements
            pointID={pointID}
            availiablePointIDs={availablePointIDs}
          />
        </Panel>
      </div>
    </div>
  );
}

// ============= EOF =============================================
