// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Panel } from "primereact/panel";
import { useEffect, useState } from "react";
import LocationDetail from "../LocationDetail/LocationDetail";
import PointIDSelector from "../PointIDSelector";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFiefTokenInfo } from "@fief/fief/react";

import "./Location.css";
import { Divider } from "primereact/divider";

export default function Location() {
  const [pointID, setPointID] = useState("");
  const [selectedPointID, setSelectedPointID] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  //add authentication check for search bar to appear
  const tokenInfo = useFiefTokenInfo();

  //Set the pointID to the selected pointID from the URL
  useEffect(() => {
    const pointid = searchParams.get("pointid");
    if (pointid && pointid !== selectedPointID) {
      setSelectedPointID(pointid);
      setPointID(pointid);
    }
  }, [searchParams, selectedPointID]);

  //If the selectedPointID changes, navigate to the new pointID url
  useEffect(() => {
    if (selectedPointID && selectedPointID !== searchParams.get("pointid")) {
      navigate(`/location/${selectedPointID}`, { replace: true });
    }
  }, [selectedPointID, navigate, searchParams]);

  //Handle the change of the pointID
  const handlePointIDChange = (newPointID) => {
    setPointID(newPointID);
  };

    //Handle the selection of a pointID
  const handlePointIDSelect = (newPointID) => {
    setSelectedPointID(newPointID);
  };

  return (
    <Panel>
        {tokenInfo && (
            <><div className={"sticky-search-location"}>
            <div className="flex flex-column align-items-center w-full">
            <p className={"text-center pt-3"}>
                Search for another PointID:
            </p>
            </div>
            <div className="flex justify-center items-center">
                <div className="p-inputgroup flex w-12 lg:w-6 justify-content-center items-align-center flex-wrap mx-auto mb-1">
                    <PointIDSelector
                        pointID={pointID}
                        setPointID={handlePointIDChange}
                        setParentFilteredSuggestions={() => { } }
                        onSelect={handlePointIDSelect} />
                </div>
            </div>
            <Divider />
            </div>
            </>
        )}

        <LocationDetail pid={selectedPointID} />
      
    </Panel>
  );
}
// ============= EOF =============================================
