// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import Plot from "react-plotly.js";
import { retrieveItems } from "../../util";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { downloadCSV } from "../download_util";
import MapComponent from "../Map/MapComponent";
import { Checkbox } from "primereact/checkbox";
import * as turf from "@turf/turf";
import { MultiSelect } from "primereact/multiselect";

const AGENCIES = [
  { name: "PVACD", code: "PVACD" },
  { name: "EBID", code: "EBID" },
  { name: "BernCo", code: "BernCo" },
];
const BASE_ST_URL = "https://st2.newmexicowaterdata.org/FROST-Server/v1.1/";

export default function STThings() {
  // A simple component to display the ST Locations
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [resultsData, setResultsData] = useState();
  const [excludeNoData, setExcludeNoData] = useState(true);

  const mapStyle = "mapbox://styles/mapbox/satellite-streets-v11";
  const mapRef = useRef();
  const [layout, setLayout] = useState({
    height: 400,
    width: 600,
    xaxis: { title: "Date" },
    yaxis: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  });
  const [plotData, setPlotData] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);

  const loadDatastreams = () => {
    setLoading(true);

    let url =
      BASE_ST_URL +
      "Datastreams?$expand=Thing/Locations&$orderby=Thing/Locations/name";
    if (excludeNoData) {
      url += "&$filter=year(resultTime) gt 0";
    }

    if (selectedAgencies && selectedAgencies.length > 0) {
      let agencyFilter = selectedAgencies
        .map((agency) => {
          return `Thing/properties/agency eq '${agency.name}'`;
        })
        .join(" or ");
      url += `&$filter=(${agencyFilter})`;
    }

    fetch(url)
      .catch((e) => {
        console.error(e);
        setLoading(false);
      })
      .then((response) => response.json())
      .then((data) => {
        data.value.forEach((d) => {
          d.location_name = d.Thing.Locations.map((loc) => loc.name).join(",");
          d.location_id = d.Thing.Locations.map((loc) => loc["@iot.id"]).join(
            ",",
          );
          d.thing_id = d.Thing["@iot.id"];
        });

        setItems(data.value);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDatastreams();
  }, [excludeNoData, selectedAgencies]);

  const plot_locations = (items) => {
    // plot locations on map from a list of SensorThings Things
    // if only one location, fly to it
    // if multiple locations, fit bounds to all locations

    if (!items) return;
    if (items.length === 0) return;

    let locs = items.map((item) => {
      return {
        type: "Feature",
        geometry: item.Thing.Locations[0].location,
        properties: {
          source: item.Thing.properties.agency,
          name: item.Thing.properties.agency,
        },
      };
    });

    let fc = turf.featureCollection(locs);
    setResultsData(fc);
    if (locs.length === 1) {
      mapRef.current.flyTo({
        center: locs[0].geometry.coordinates,
        zoom: 12,
      });
    } else {
      // if locations are very close to each other, fly to first location

      let bbox = turf.bbox(fc);
      let a = turf.area(turf.bboxPolygon(bbox));

      if (a < 100) {
        mapRef.current.flyTo({
          center: locs[0].geometry.coordinates,
          zoom: 12,
        });
      } else {
        mapRef.current.fitBounds(bbox, { padding: 100 });
      }
    }
  };

  const plot_hygrogaphs = (items) => {
    let promises = items.map((item) => {
      return retrieveItems(
        item["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc",
        [],
      ).then((data) => {
        return {
          mode: item.name.includes("Manual") ? "markers" : "lines",
          x: data.map((s) => s.phenomenonTime),
          y: data.map((s) => s.result),
          name: item.Thing.Locations[0].name + " " + item.name,
        };
      });
    });

    setLoading(true);
    Promise.all(promises)
      .then((data) => {
        setTimeSeriesData(data);
        setPlotData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!selectedItem) return;

    // plot locations on map
    plot_locations(selectedItem);

    // plot hydrographs
    plot_hygrogaphs(selectedItem);
  }, [selectedItem]);

  const handleDownload = (rowData) => {
    let obsUrl =
      rowData["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc";
    setLoading(true);
    retrieveItems(obsUrl, []).then((data) => {
      setLoading(false);
      let rows = data.map((s) => {
        return [
          s.phenomenonTime,
          s.result,
          s["@iot.selfLink"],
          s["Datastream@iot.navigationLink"],
        ];
      });
      console.log(rowData);
      downloadCSV(`${rowData.Thing.Locations[0].name}`, rows, [
        "Date",
        "Result",
        "SelfLink",
        "DatastreamLink",
      ]);
      // console.log("asdasdfsdfasda", data);
      // setPlotData([
      //   {
      //     x: data.map((s) => s.phenomenonTime),
      //     y: data.map((s) => s.result),
      //   },
      // ]);
    });
  };
  const clearFilter = () => {
    initFilters();
  };
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue("");
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter}
        />
        <div>
          <Checkbox
            inputId="exclude_no_data"
            name="exclude_no_data"
            // onChange={(e) => {
            //   setDownloadConfig({ ...downloadConfig, location_info: e.checked });
            // }}
            onChange={(e) => setExcludeNoData(e.checked)}
            checked={excludeNoData}
          />
          <label htmlFor="exclude_no_data" className="ml-2">
            Exclude Datastreams with no data
          </label>
        </div>
        <MultiSelect
          value={selectedAgencies}
          options={AGENCIES}
          onChange={(e) => setSelectedAgencies(e.value)}
          optionLabel="name"
          placeholder="Select one or more agencies"
          display="chip"
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  useEffect(() => {
    initFilters();
  }, []);

  const header = renderHeader1();
  return (
    <div className={"grid"}>
      <div className={"col-7"}>
        <DataTable
          header={header}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={[
            "Thing.properties.agency",
            "@iot.id",
            "name",
            "location_name",
            "location_id",
            "thing_id",
          ]}
          // selectionMode="single"
          selection={selectedItem}
          onSelectionChange={(e) => setSelectedItem(e.value)}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[25, 50, 100]}
          className={"compact-table"}
          size={"small"}
          value={items}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
          <Column header={"Agency"} field={"Thing.properties.agency"} />
          <Column sortable header={"Name"} field={"name"} />
          <Column header={"Location Name"} field={"location_name"} />

          <Column
            header={"ID"}
            field={"@iot.id"}
            body={(rowData) => {
              return (
                <a href={rowData["@iot.selfLink"]} target="_blank">
                  {rowData["@iot.id"]}
                </a>
              );
            }}
          />
          {/*<Column header={"Description"} field={"description"} />*/}
          {/*<Column header={"ResultTime"} field={"resultTime"} />*/}
          {/*<Column*/}
          {/*  sortable*/}
          {/*  header={"UnitOfMeasurement"}*/}
          {/*  field={"unitOfMeasurement.name"}*/}
          {/*/>*/}
          <Column
            sortable
            header={"Thing"}
            body={(rowData) => {
              return (
                <a
                  key={rowData.Thing["@iot.id"]}
                  href={rowData.Thing["@iot.selfLink"]}
                  target="_blank"
                >
                  {rowData.Thing["@iot.id"]}
                </a>
              );
            }}
          />

          <Column
            sortable
            field={"location"}
            header={"Location"}
            body={(rowData) => {
              return rowData.Thing.Locations.map((loc) => {
                return (
                  <a
                    key={loc["@iot.id"]}
                    href={loc["@iot.selfLink"]}
                    target="_blank"
                  >
                    {loc["@iot.id"]}
                  </a>
                );
              });
            }}
          />
          <Column
            header={"Download"}
            body={(rowData) => {
              return (
                <div className="p-inputgroup flex-1">
                  <div className={"mr-1"}>
                    <Button
                      className={"small-button"}
                      tooltip={"View Hydrograph"}
                      icon={"pi pi-chart-line"}
                      onClick={(e) => {
                        // notImplemented("View");
                        setSelectedItem(rowData);
                      }}
                    />
                  </div>
                  <div className={"mr-1"}>
                    <Button
                      className={"small-button"}
                      tooltip={"Download"}
                      icon={"pi pi-download"}
                      onClick={(e) => {
                        handleDownload(rowData);
                      }}
                    />
                  </div>
                </div>
              );
            }}
          />
        </DataTable>
      </div>
      <div className={"col-5"}>
        <div className={"relative"}>
          <div className={"absolute bottom-50 right-50 z-1"}>
            {loading && <ProgressSpinner strokeWidth={5} />}
          </div>
          <Plot
            data={plotData}
            layout={layout}
            // config={{ responsive: true }}
          />
        </div>
        <div>
          <MapComponent
            mapRef={mapRef}
            mapStyle={mapStyle}
            initialViewState={{
              longitude: -106.0, //-108.0,
              latitude: 35, //37.0,
              zoom: 5, //8,
            }}
            sourceData={{ results: resultsData }}
            sources={[
              {
                id: "results",
                label: "Results",
                url: "foob",
                paint: {
                  "circle-radius": 5,
                  "circle-stroke-width": 1,
                  "circle-stroke-color": "#000000",
                  "circle-color": "#f3ce14",
                },
              },
            ]}
          ></MapComponent>
        </div>
      </div>
    </div>
  );
}
// ============= EOF =============================================
