// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import banner from "../../img/Bosque above San Antonio1.jpg";
// import banner from "../../img/ChamaWall_0055.jpg";
// import banner from "../../img/Rio Grande at Santa Ana Pueblo.jpg";
// import weaverlogo from "../../img/weaverlogo.png";
import { Message } from "primereact/message";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import MainCard from "./MainCard";
import DataSourceComponent from "./DataSourceComponent";

const Banner = () => {
  return (
    <div className={"flex flex-column md:flex-row bg-blue-100 relative justify-content-center align-items-center md:align-items-start"}>
      <div className={"w-full h-15rem overflow-hidden"}>
        <img src={banner} alt="banner" className={"w-full object-cover"} />
      </div>
      <div className={"lg:w-5 mg:w-8 sm:w-8 flex absolute"}>
        <div className={"surface-300 border-round m-3 p-1"}>
          <h1>NMWDI Weaver</h1>
          <p className={"m-3"}>
            This site serves as a data integration and discovery platform for
            the New Mexico Water Data Initiative (NMWDI). NMWDI is a
            collaborative effort to improve the availability and accessibility
            of water data in New Mexico.
          </p>
        </div>
      </div>
    </div>
  );
};

const MessageBanner = () => {
  return (
    <div className={"p-3"}>
      <Message
        severity="warn"
        text={
          "This site is currently in active development. Features and data are subject to change"
        }
      />
    </div>
  );
};
export default function Home() {
  return (
    <div className={"text-center"}>
      <MessageBanner />
      <Banner />

      <div className={"flex"}>
        <div className={"grid pr-4 pl-4 pt-4 lg:pr-8 lg:pl-8"}>
        <div className={"md:col-6 lg:col-4 sm:col-12 mb-3 md:mb-0"}>
          <MainCard title={"Groundwater Dashboard"} href={"/groundwater"}>
            <p>
            The Groundwater Dashboard will provide access to data from the New Mexico Bureau of Geology and Mineral Resources, New Mexico Office of State Engineer, the USGS, and other sources. This page will provide detailed information and data from wells around the state. 
            </p>
          </MainCard>
        </div>
        <div className={"md:col-6 lg:col-4 sm:col-12 mb-3 md:mb-0"}>
          <MainCard title={"Healy Collaborative Network"} href={"/collabnet"}>
            <p>
            The New Mexico Bureau of Geology and Mineral Resource's Aquifer Mapping Program is actively monitoring the Healy Collaborative Groundwater Monitoring Network for New Mexico. Our primary focus is rural and under-monitored regions throughout the state. Our public groundwater level data is meant to benefit all water users.
            </p>
          </MainCard>
        </div>
        <div className={"md:col-6 lg:col-4 sm:col-12"}>
          <MainCard title={"Data Integration Engine"} href={"/die"}>
            <p>
            The Data Integration Engine is a tool that allows users to spatially filter and integrate data from multiple sources, and provides a unified dataset.
            </p>
          </MainCard>
        </div>
        </div>

        {/*disable for now until the respective pages are ready*/}
        {/*<div className={'col-4'}>*/}
        {/*    <MainCard title={"Groundwater Chemistry"}*/}
        {/*          href={'/groundwater'}>*/}
        {/*    </MainCard>*/}
        {/*</div>*/}
        {/*<div className={'col-4'}>*/}
        {/*    <MainCard title={"Surface Water"}*/}
        {/*          href = {'/surfacewater'}>*/}
        {/*    </MainCard>*/}
        {/*</div>*/}
      </div>
      <Divider />
      <DataSourceComponent />
      <Divider />
      <div className="surface-0 text-700 text-center p-3 md:p-6">
        <div className="text-900 font-bold text-3xl md:text-5xl mb-3">
          Join Our Community
        </div>
        <div className="text-700 text-lg md:text-2xl mb-5">
          We are a group of Water Data Specialists trying to improve the flow of
          data from producer to consumer
        </div>
        <Button
          label="Join Now"
          icon="pi pi-slack"
          style={{ marginBottom: "10px" }}
          className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
          onClick={() =>
            window.open("https://new-mexico-water-data.slack.com", "_blank")
          }
        />
      </div>
    </div>
  );
}
// ============= EOF =============================================
