// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { nmbgmr_getJson } from "../../util";
import { DataTable } from "primereact/datatable";
import { useRef, useState } from "react";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { downloadCSV } from "../download_util";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import { Divider } from "primereact/divider";

export default function SiteCrossRef() {
  const [results, setResults] = useState([]);
  const [site, setSite] = useState("");
  const [site_type, setSiteType] = useState("USGS");
  const [loading, setLoading] = useState(false);
  const [siteValid, setSiteValid] = useState(true);
  const toast = useRef(null);

  const resultsHeader = (options) => {
    return (
      <div className={options.className}>
        <div>{options.titleElement}</div>
        <div>
          <Button
            icon={"pi pi-download"}
            tooltip={"Export as CSV"}
            tooltipOptions={{ showDelay: 500, position: "top" }}
            className={"p-button-outlined"}
            onClick={handleDownload}
          />
          {options.togglerElement}
        </div>
      </div>
    );
  };

  // const toast = useRef(null);

  // const accept = () => {
  //   toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  // }
  //
  // const reject = () => {
  //   toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  // }

  const handleDownload = () => {
    console.log("download");
    let rs = results.filter((r) => r !== undefined);
    let rows = rs.map((r) => [
      r.nmbgmr.id,
      r.nmbgmr.location.latitude,
      r.nmbgmr.location.longitude,
      r.nmbgmr.well_depth,
      r.nmbgmr.hole_depth,
      r.nmbgmr.distance,
      r.usgs.id,
      r.usgs.location.latitude,
      r.usgs.location.longitude,
      r.usgs.distance,
      r.ose.id,
      r.ose.distance,
      r.ose.well_depth,
      r.ose.link,
    ]);
    let header = [
      [
        "NMBGMR ID",
        "NMBGMR Latitude",
        "NMBGMR Longitude",
        "NMBGMR Well Depth",
        "NMBGMR Hole Depth",
        "NMBGMR Candidate Distance",
        // "NMBGMR ID Candidates",
        "USGS ID",
        "USGS Latitude",
        "USGS Longitude",
        "USGS Candidate Distance",
        // "USGS ID Candidates",
        "OSEPOD ID",
        "OSEPOD Candidate Distance",
        "OSEPOD Well Depth",
        "NMWRRS WRS Link",
        // "OSEPOD ID Candidates",
      ],
    ];
    header.push(...rows);
    downloadCSV("site_cross_reference.csv", header);
  };
  const handleSubmit = async () => {
    console.log("submit");
    if (site === "") {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please enter one or more site ids",
        life: 3000,
      });
      setSiteValid(false);
      return;
    }

    setLoading(true);
    setSiteValid(true);
    let site_type_id;
    if (site_type === "NMBGMR") {
      site_type_id = "nmbgmrID";
    } else if (site_type === "USGS") {
      site_type_id = "usgsID";
    } else if (site_type === "OSEPOD") {
      site_type_id = "oseID";
    }

    let sites;
    if (site.includes(",")) {
      sites = site.split(",");
    } else if (site.includes("\n")) {
      sites = site.split("\n");
    } else {
      sites = [site];
    }
    setResults([]);
    for (let s of sites) {
      if (s.trim() === "") continue;
      let row = await fetchSite(s, site_type_id);
      setResults((prev) => [...row, ...prev]);
    }
    setLoading(false);
  };

  const make_ose_candidate_rows = (data) => {
    let rows = [];
    for (let c of data.ose_id.candidates) {
      rows.push({
        nmbgmr: {
          ...data.nmbgmr_id,
          distance: data.nmbgmr_id.id ? "db match" : "",
          // join_candidates: data.nmbgmr_id.candidates.join(";"),
        },
        usgs: {
          ...data.usgs_id,
          distance: 0,
          // join_candidates: data.usgs_id.candidates.join(";"),
        },
        ose: {
          id: c.id,
          distance: c.distance.toFixed(2),
          well_depth: c.well_depth,
          link: c.link,
        },
      });
    }
    return rows;
  };
  const fetchSite = async (site, site_type_id) => {
    let q = `${site_type_id}=${site}`;
    return nmbgmr_getJson(`site-service?${q}`).then((data) => {
      if (data !== undefined) {
        console.log(data);
        if (data.ose_id.candidates.length > 0) {
          return make_ose_candidate_rows(data);
        } else {
          return [
            {
              nmbgmr: {
                ...data.nmbgmr_id,
                distance: data.nmbgmr_id.id ? "db match" : "",
              },
              usgs: {
                ...data.usgs_id,
                distance: 0,
              },
              ose: {
                id: data.ose_id.id,
                well_depth: data.ose_id.well_depth,
              },
            },
          ];
        }
      }
      let si;
      switch (site_type) {
        case "NMBGMR":
          si = {
            nmbgmr: { id: site },
            usgs: { id: "" },
            ose: { id: "" },
          };
          break;
        case "USGS":
          si = {
            nmbgmr: { id: "" },
            usgs: { id: site },
            ose: { id: "" },
          };
          break;
        case "OSEPOD":
          si = {
            nmbgmr: { id: "" },
            usgs: { id: "" },
            ose: { id: site },
          };
          break;
      }

      return [si];
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className={"grid"}>
        <div className={"col-6 col-offset-3"}>
          <Panel>
            <h1>Site Cross Reference</h1>
            <Message
              text={
                "The Site Cross Reference tool is in active development and may not be accurate.  Use with caution."
              }
              severity={"warn"}
            />
            <p>
              This tool is used to cross reference site IDs between NMBGMR,
              USGS, and NM OSE PODs.
            </p>
            <p>
              It uses each agencies API to look up the relevant site
              information. See{" "}
              <a
                href="https://waterdata.nmt.edu"
                target={"_blank"}
                rel="noreferrer"
              >
                NMBGMR
              </a>
              ,{" "}
              <a
                href="https://waterservices.usgs.gov"
                target={"_blank"}
                rel="noreferrer"
              >
                USGS
              </a>
              , and{" "}
              <a
                href="https://services2.arcgis.com/qXZbWTdPDbTjl7Dy/arcgis/rest/services/OSE_PODs/FeatureServer"
                target={"_blank"}
                rel="noreferrer"
              >
                NM OSE PODs
              </a>{" "}
              for more information on each API used.
            </p>

            <p>
              The process used to cross reference sites:
              <ol>
                <li>Look for the OSE ID and NMBGMR ID in NMBGMR Database</li>
                <li>If the NMBGMR ID is found but the OSE ID is not ... </li>
                <ol>
                  <li>
                    Get the Latitude and Longitude from the NMBGMR database
                  </li>
                  <li>
                    Search the OSE PODs database for any PODs with 500m of given
                    Latitude/Longitude
                  </li>
                </ol>
                <li>
                  Return a table of results. Each row represents a possible
                  match
                </li>
              </ol>
            </p>

            <div className={"p-inline-message p-inline-message-info"}>
              <span className={"pi pi-info-circle"}></span>
              <div className={"pl-2"}>
                If a site is found in the NMBGMR database, the distance to the
                candidate site will be show as
                <code className={"bg-gray-300 pl-2 pr-2 border-round"}>
                  db match
                </code>
              </div>
            </div>
            <Divider />
            <Message
              text={
                "This tool currently only supports entering USGS site IDs.  Entering NMBGMR PointIDs and" +
                " NMOSE PODs support is coming soon. Please check back later."
              }
              severity={"warn"}
            />
          </Panel>
        </div>
        <div className={"col-6 col-offset-3"}>
          <div className={"field"}>
            <label htmlFor="Site Type" className="block">
              Site Type
            </label>
            <Dropdown
              value={site_type}
              onChange={(e) => setSiteType(e.target.value)}
              id="site_type"
              placeholder="NMBGMR"
              options={["USGS"]}
              // options={["USGS", "OSEPOD", "NMBGMR"]}
            />
            <small id="sites-help" className="block">
              Select the input site id type
            </small>
          </div>
        </div>
        <div className={"col-6 col-offset-3"}>
          <div className="field">
            <label htmlFor="sites" className="block">
              Sites
            </label>
            <InputTextarea
              placeholder={
                "e.g. 323330104272301\n" +
                "323359104233801\n" +
                "323402104214901\n"
              }
              className={siteValid ? "block" : "p-invalid"}
              value={site}
              onChange={(e) => setSite(e.target.value)}
              id="sites"
              aria-describedby="sites-help"
            />
            <small id="sites-help" className="block">
              Enter one or more site IDs separated by commas or newlines
            </small>
          </div>
        </div>
        <div className={"col-6 col-offset-3"}>
          <Button loading={loading} label={"Submit"} onClick={handleSubmit} />
        </div>
        <Divider />
        <div className={"col-10 col-offset-1"}>
          <Panel header={"Results"} headerTemplate={resultsHeader}>
            <DataTable
              stripedRows
              size={"small"}
              className={"compact-table"}
              value={results}
              paginator
              rows={10}
            >
              <Column
                field="nmbgmr.id"
                className={"font-bold"}
                header="NMBGMR ID"
              />
              <Column
                field={"nmbgmr.location.latitude"}
                header={"NMBGMR Latitude"}
                body={(rowData) => {
                  return rowData.nmbgmr.location.latitude.toFixed(5);
                }}
              />
              <Column
                field={"nmbgmr.location.longitude"}
                header={"NMBGMR Longitude"}
                body={(rowData) => {
                  return rowData.nmbgmr.location.longitude.toFixed(5);
                }}
              />
              <Column
                field={"nmbgmr.well_depth"}
                header={"NMBGMR Well Depth (ft)"}
              />
              <Column
                field={"nmbgmr.hole_depth"}
                header={"NMBGMR Hole Depth (ft)"}
              />
              <Column
                field="nmbgmr.distance"
                header="NMBGMR Candidate Distance (km)"
              />
              <Column
                field="usgs.id"
                header="USGS ID"
                className={"font-bold"}
              />
              <Column
                // field={"usgs.location.latitude"}
                header={"USGS Latitude"}
                body={(rowData) => {
                  return rowData.usgs.location.latitude.toFixed(5);
                }}
              />
              <Column
                header={"USGS Longitude"}
                body={(rowData) => {
                  return rowData.usgs.location.longitude.toFixed(5);
                }}
              />
              <Column
                field="usgs.distance"
                header="USGS Candidates Distance (km)"
              />
              <Column
                field="ose.id"
                header="OSEPOD ID"
                className={"font-bold"}
              />
              <Column
                field="ose.link"
                body={(rowData) => {
                  if (rowData.ose.link === undefined) {
                    return "";
                  }
                  return (
                    <Button
                      text
                      className={"p-0"}
                      icon={"pi pi-external-link"}
                      onClick={() => {
                        window.open(rowData.ose.link, "_blank");
                      }}
                    />
                  );
                }}
                header="NMWRRS WRS Link"
              />
              <Column
                field="ose.distance"
                header="OSEPOD Candidate Distance (km)"
              />
              <Column
                field={"ose.well_depth"}
                header={"OSEPOD Well Depth (ft)"}
              />
            </DataTable>
          </Panel>
        </div>
      </div>
    </div>
  );
}
// ============= EOF =============================================
/*
323330104272301
323359104233801
323402104214901
323409104281401
323442104263901
323516104245301
323541104243101
323542104242701
323546104251601
323615104220801
323705104225501
323737104232501
323738104253801
323810104221601
323818104405701
323917104235201
323929104210702
323929104210703
324006104210801
324034104201801
324105104222601
324154104210701
324249104220601
324250104224101
324325104233001
324340104202301
324418104194701
324418104203301
324431104274601
324501104223701
324522104195901
324553104250201
324612104213301
324614104244101
324620104255001
324620104255101
324622104430401
324654104233701
324728104271901
324831104244401
324838104435301
324847104203602
324943104233801
325032104224005
325032104251301
325032104252001
325033104220201
325217104205501
325225104210501
325228104203901
325230104201901
325246104203401
325309104233601
325314104230802
325426104250901
325450104251101
325454104251401
325516104404601
325633104274601
325638104274801
325645104262501
325656104315401
325702104352801
325730104314801
325838104335901
325841104251501
325845104295501
325919104231101
325932104193001
330026104222201
330026104224101
330118104230101
330216104201201
330236104231101
330245104165601
330248104161901
330248104161902
330253104163701
330257104162701
330309104163401
330315104184701
330324104221001
330404104221201
330452104273901
330454104231201
330455104354201
330528104272301
330547104200701
330608104193701
330641104222601
330646104173301
330702104402401
330714104180401
330730104235101
330805104243901
330817104240001
330838104260701
331005104205401
331059104185701
331124104275501
331125104215701
331138104210001
331152104231501
331213104241601
331241104195501
331248104194801
331303104231901
331357104245001
331401104252001
331429104221301
331524104245101
331532104255202
331553104252101
331645104305201
331648104302901
331703104222901
331705104262801
331728104222901
331751104383201
331756104383701
331812104211801
331840104212301
331846104211001
331853104213301
331854104211201
331854104212101
331855104205101
331901104211501
331903104211101
331903104211102
331914104245501
331914104253701
331937104213501
331940104310901
331946104214501
331946104214502
331952104311001
331956104213501
332007104361801
332105104285401
332112104285901
332150104353201
332231104290701
332244104335501
332255104360401
332306104403901
332307104260801
332309104260801
332337104321801
332347104235301
332348104245101
332348104245102
332350104242001
332350104243601
332413104371101
332535104260503
332613104355501
332629104232701
332704104252301
332709104321401
332731104231601
332738104230101
332743104225301
333031104310301
333145104315901
333344104315901
333432104282301
333636104294701
333735104300301
333908104314401
334138104343801

 */
