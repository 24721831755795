import { toCSV } from "react-csv/lib/core";

function down(fileName, text) {
  const url = window.URL.createObjectURL(new Blob([text]));
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName || "downloaded-file";
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);

  // var element = document.createElement("a");
  // element.setAttribute(
  //   "href",
  //   "data:text/plain;charset=utf-8," + encodeURIComponent(text),
  // );
  // element.setAttribute("download", filename);
  // console.log("a");
  // element.style.display = "none";
  // document.body.appendChild(element);
  // console.log("b");
  // element.click();
  // console.log("asdfasdfa", element);
  // document.body.removeChild(element);
}

export function downloadGeoJSON(filename, data) {
  down(filename, JSON.stringify(data));
}

export function downloadCSVfromText(filename, text) {
  down(filename, text);
}
export function downloadZip(filename, zip) {
  console.log("asd", zip);
  const url = window.URL.createObjectURL(zip, {
    type: "application/zip",
  });
  const link = document.createElement("a");
  link.href = url;
  link.download = filename || "downloaded-file";
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export function downloadCSV(filename, rows, headers = null) {
  if (headers === null) {
    headers = rows.shift();
  }
  down(filename + ".csv", toCSV(rows, headers, ",", ""));
}
export function downloadTSV(filename, rows, headers = null) {
  if (headers === null) {
    headers = rows.shift();
  }
  down(filename + ".tsv", toCSV(rows, headers, "\t", ""));
}
