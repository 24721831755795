// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
export const die = {
  id: "die",
  label: "Data Integration Engine",
  icon: "pi pi-fw pi-home",
  command: () => {
    window.location.href = "/die";
  },
};

export const chem_upload = {
  id: "chem_upload",
  label: "Chemistry Upload",
  icon: "pi pi-fw pi-cloud-upload",
  command: () => {
    window.location.href = "/chem_upload";
  },
};

export const well_upload = {
  id: "well_upload",
  label: "Well Upload",
  icon: "pi pi-fw pi-cloud-upload",
  command: () => {
    window.location.href = "/well_upload";
  },
};

export const batch_manual_waterlevels_upload = {
  id: "batch_manual_waterlevels_upload",
  label: "Batch Manual Waterlevels Upload",
  icon: "pi pi-fw pi-cloud-upload",
  command: () => {
    window.location.href = "/batch_manual_waterlevels_upload";
  },
};

export const manual_water_level_entry = {
  id: "manual_water_level_entry",
  label: "Manual Water Level Entry",
  icon: "pi pi-fw pi-table",
  command: () => {
    window.location.href = "/manual_water_level_entry";
  },
};

export const data_view = {
  id: "data_view",
  label: "Data View",
  icon: "pi pi-fw pi-table",
  command: () => {
    window.location.href = "/dataview";
  },
};

export const continuous = {
  id: "continuous",
  label: "Continuous",
  icon: "pi pi-fw pi-table",
  command: () => {
    window.location.href = "/continuous";
  },
};
// ============= EOF =============================================
