// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

export default function Disclaimer() {
  return (
    <div>
      <h1> Disclaimer</h1>
      <p>
        {" "}
        At NMBGMR, we use different tools to collect groundwater level
        measurements, including continuous data recorders and manual
        measurements. All data provided here are in feet, depth to water, below
        ground surface (BGS). We use pressure transducers to record pressure of
        water over a device installed in the well, which is converted to feet of
        water and depth to water. We provide here up to one measurement per hour
        where the data are that frequent. In some locations we have more data
        available. We also use continuous acoustic sounder devices which convert
        a sound reflection into a measurement of depth to water. These can be
        used for long term trends in groundwater levels. While we do our best to
        review and quality check these data, please use these data with caution.
        Site-specific conditions should be verified, especially for legally
        binding decisions. Data are subject to changes, deletion, or being moved
        without notice at any time and should not be relied on for any critical
        application. Any opinions expressed may not necessarily reflect the
        official position of the New Mexico Bureau of Geology, New Mexico Tech,
        or the State of New Mexico. No warranty expressed or implied, is made
        regarding the accuracy or utility of the data for general or scientific
        purposes.
      </p>

      <p>
        This geospatial data is being provided to the public as resource to aid
        in the understanding of the resources of New Mexico. However, there are
        limitations for all data, particularly when aggregated with other data
        that may have been collected at different times, by different agencies
        or people, and for different purposes. All geospatial data sets are
        inherently scale-dependent and may falsely imply relationships with
        other data or a false level of accuracy when zoomed-in beyond the scale
        of analysis. Users of this data should carefully read the included
        metadata for each data set. Site-specific conditions should also be
        verified, especially for legally binding decisions. Users are also
        welcome to contact us for clarification regarding the strengths and
        limitations our data.
      </p>
    </div>
  );
}
// ============= EOF =============================================
