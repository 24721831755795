// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

export const ebid = {
  name: "EBID",
  code: "ebid",
  description: "Elephant Butte Irrigation District.",
};

export const usgs = {
  name: "USGS NWIS",
  code: "nwis",
  description: "USGS National Water Information System.",
};
export const nmbgmr = {
  name: "NMBGMR",
  code: "nmbgmr",
  description: "New Mexico Bureau of Geology and Mineral Resources.",
};
const dwb = {
  name: "NMED DWB",
  code: "dwb",
  description: "New Mexico Environment Department Drinking Water Bureau.",
};
const wqp = { name: "WQP", code: "wqp", description: "Water Quality Portal." };
const bor = {
  name: "USBR",
  code: "bor",
  description: "US Bureau of Reclamation.",
};
const pvacd = {
  name: "PVACD",
  code: "pvacd",
  description:
    "Pecos Valley Artesian Conservancy Districy via New Mexico State Water Data Initiative.",
};
const isc_seven_rivers = {
  name: "ISC Seven Rivers",
  code: "iscsevenrivers",
  description: "Interstate Stream Commission Seven Rivers District Office.",
};
const ose_roswell = {
  name: "OSE Roswell District Office",
  code: "oseroswell",
  description:
    "New Mexico Office of the State Engineer Roswell District Office.",
};
const bernco = {
  name: "Bernalillo County",
  code: "bernco",
  description: "Bernallio County Water Utility Authority.",
};

export const dtw_sources = [
  usgs,
  nmbgmr,
  pvacd,
  isc_seven_rivers,
  ose_roswell,
  bernco,
];
export const analyte_sources = [dwb, nmbgmr, wqp, bor, isc_seven_rivers];
export const all_sources = [
  usgs,
  dwb,
  bor,
  wqp,
  nmbgmr,
  pvacd,
  isc_seven_rivers,
  ose_roswell,
  bernco,
];
// ============= EOF =============================================
